
import axios from "axios"
import EvaluationDraftChangeMainComp from "../../components/EvaluatuinManagmentSettingsComps/EvaluationDraftsSettingsChange/EvaluationDraftChangeMainComp/EvaluationDraftChangeMainComp"
import EvaluationTimeSettings from "../../components/EvaluatuinManagmentSettingsComps/EvaluationTimeSettings/EvaluationTimeSettings"

import "./evaluationSettingsManagment.scss"
import { useSelector } from "react-redux"
import { useEffect, useState } from "react"
import { TailSpin } from "react-loader-spinner"

const EvaluationSettingsManagment = () => {

  const token = useSelector(state => state.user.token)

  const [tableLabels,setTableLabels] = useState();

  const [loading,setLoading] = useState(false)

  const [error,setError] = useState(null)


  //fetch the drafts table labels 
  
  const fetchDraftTableLabel = async() => {

    try {
      setLoading(true);
      const response = await axios.get(
        `https://emp.almatingroup.com/api/V1/evaluation-labels`,
     
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      console.log('Response tabel lables:', response.data.data.data);
      setTableLabels(response.data.data.data);
      setLoading(false);
      setError(null);
    } catch (error) {
      console.error('Error:', error);
      setLoading(false);
      setError(error.message)
  
    } 
  }


  useEffect(()=>{
    fetchDraftTableLabel();
  },[token])




  
  return (
    <div className="EvaluationSettingsManagment">

        <div className="EvaluationSettingsManagment_container">

          {
            error && (
              <span style={{color:"red",marginTop:"1rem"}} dir="rtl">{error}</span>
            )
          }

          
      {
         loading ?
          <div className='spinner_getData'  style={{alignSelf:"center"}}>
          <TailSpin
          visible={true}
          height="40"
          width="40"
           color="rgb(246,229,56)"
          ariaLabel="tail-spin-loading"
          radius="1"
          wrapperStyle={{}}
          wrapperClass=""
          />
        </div>
          :
          <>      
          
            <span className="EvaluationSettingsManagment_container_header" dir="rtl">صفحة اعدادات التقييم : </span>


           <div className="EvaluationSettingsManagment_container_items">
              <EvaluationTimeSettings/>

              <EvaluationDraftChangeMainComp tableLabels = {tableLabels}/>
           </div>
        
        
           </>
         
         }


        </div>


    </div>
  )
}

export default EvaluationSettingsManagment