import React, { useEffect, useState } from 'react'
import './departmentEmps.scss'
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { TailSpin } from 'react-loader-spinner'
import { useNavigate } from 'react-router-dom'

function DepartmentEmps() {

  const navigate = useNavigate();

  const token = useSelector(state => state.user.token)

  const authMe = useSelector(state => state.authme.data);

  const [empsArr,setEmpsArr] = useState([])

  const [loadingResult,setLoadingResult] = useState(false)

  const [currentPage, setCurrentPage] = useState(1);
  
  const [totalPages, setTotalPages] = useState(1);

  const [error,setError] = useState(null)


  
  useEffect(() => {

    fetchEmps(1);
 
   }, [token ]);


  
  const fetchEmps = async (page) => {

    if( authMe?.is_manager){
  
  
    try {
      setLoadingResult(true);
      const response = await axios.get(
        `https://emp.almatingroup.com/api/V1/users/department-users/all?page=${page}`,
     
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      console.log('Response:', response.data.data.data);
      setEmpsArr(response.data.data.data);
      setCurrentPage(response.data.data.current_page);
      setTotalPages(response.data.data.last_page);
      setLoadingResult(false);
    } catch (error) {
      console.error('Error:', error);
      setLoadingResult(false);
      setError(error.message)
  
    }
  }
  
  };



 
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };



  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      fetchEmps(page);
    }
  };
  

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const startPage = Math.max(1, currentPage - 2);
    const endPage = Math.min(totalPages, currentPage + 2);
  
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <button
          key={i}
          onClick={() => handlePageChange(i)}
          className={currentPage === i ? 'buttonNum_pagination active' : 'buttonNum_pagination'}
        >
          {i}
        </button>
      );
    }
  
    return pageNumbers;
  };


//for handeling the requesting for exist permission for an employee
const handelReqForExistPer = (job_id , emp_name)=>{
  navigate('/emp/exist_per_req_manager', { state: { job_id , emp_name } });
}
  

  return (
    <div className='departmentEmps_full_page'>


        <div className='departmentEmps_container'>
            <div className='departmentEmps_container_top'><span>موظفين القسم</span></div>

        
        { 
          error &&
          (<span style={{color:"red"}}>{error}<span style={{color:"red"}}>*</span></span>)
        }

{
         loadingResult ?
          <div className='spinner_getData'>
          <TailSpin
          visible={true}
          height="40"
          width="40"
           color="rgb(246,229,56)"
          ariaLabel="tail-spin-loading"
          radius="1"
          wrapperStyle={{}}
          wrapperClass=""
          />
        </div>
          :
         <>
         
         <div className='departmentEmps_container_tableShow'>
          
          <table className='VacationsForTodayAccepted_table'>
            <thead>
            <tr className='VacationsForTodayAccepted_header_row_table'>
            <th>تقديم اجازة</th>
            <th>رقم الموبايل</th>
            <th>تاريخ المباشرة</th>
            <th>الاسم</th>
            <th>الرقم الوظيفي</th>
           </tr>
           </thead>
          
          
           <tbody >
          
          { empsArr?.map(
              obj =>(
               <tr key={obj.job_id}>
             <td dir="rtl"><button className='exist_per_btn' onClick={()=>{handelReqForExistPer(obj.job_id,obj.name)}} dir='rtl' >تقديم</button></td>
             <td dir="rtl">{obj.mobile}</td>
             <td dir="rtl">{formatDate(obj.hiring_date)}</td>
             <td dir="rtl">{obj.name}</td>
             <td dir="rtl">{obj.job_id}</td>
          </tr>
          
              )
            )}
          
          
          
          </tbody>
          
          </table>
          
          </div>
         
         </>
        }

{
      loadingResult ? 
      <>
      </>
      :
      <>
      <div className="pagination_VacationStatistics">
           <button className='buttonNum_pagination_arrow' onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
            <FaAngleLeft  className={currentPage ===  1 ? 'arrow_disabled_Icon' : ''}  />
          </button>
            
        {renderPageNumbers()}
                        
        <button className={currentPage === totalPages ? 'buttonNum_pagination_arrow arrow_disabled' : 'buttonNum_pagination_arrow'} onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
              <FaAngleRight  className={currentPage === totalPages ? 'arrow_disabled_Icon' : ''} />
        </button>
       </div>
      
      </>
    }


</div>

</div>

    


    
  )
}

export default DepartmentEmps