import React, { useEffect, useState } from 'react'

import './createNewJobPosition.scss'

import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { Puff, TailSpin } from 'react-loader-spinner';


import { createSelector } from 'reselect';
import { fetchAuthMe } from '../../../store/AuthmeSlice/authmeSlice';
import { errorHandeling } from '../../../errorHandeling';

const selectUserPermissions = createSelector(
  state => {
    const roles = state.authme.data?.roles;
    if (!roles || roles.length === 0 || !roles[0].permessions) {
      return null; // Return null instead of a new array
    }
    return roles[0].permessions;
  },
  permessions => permessions ? permessions.map(perm => perm.name) : []
);
  



function CreateNewJobPosition() {

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const token = useSelector(state => state.user.token)

    const userPermissions = useSelector(selectUserPermissions);

    const authMeStatus = useSelector(state => state.authme.status);

    
    const [jobPName,setJobPName] = useState("")
    const [jobPDes,setJobPDes] = useState("")
    const [loadingCreate,setLoadingCreate] = useState(false)

    const [error,setError] = useState(null)


    useEffect(() => {
        if (authMeStatus === 'idle') {
          dispatch(fetchAuthMe());
        }
        else if (authMeStatus === 'failed') {
          setError("*خطأ في تحميل البيانات");
        }
      }, [dispatch, authMeStatus]);



//check for permissions 

const hasPermission = (permission) => userPermissions.includes(permission);

const checkPermissionAndThrow = (permission) => {
  if (!hasPermission(permission)) {
    
    return false;
  }
  else{
    return true;
  }
};

 

    let handleSubmit = async(e)=>{
        e.preventDefault()

        if(checkPermissionAndThrow('create_job_positions')){
        const data = {
            title : jobPName,
            description:jobPDes
          }
           try {
            setLoadingCreate(true)
               const response = await axios.post(`https://emp.almatingroup.com/api/V1/job-positions`,data, {
                   headers: {
                       Authorization: `Bearer ${token}`
                   }
               });
               // const { DepName, DepManger, DepParent } = response.data.data;
               // setDepName(DepName);
               // setDepManger(DepManger);
               // setDepParent(DepParent);
          
               // console.log("the dep after fetching is:",response.data)
               setLoadingCreate(false)
           } catch (error) {
               console.error("Error fetching department data:", error);
               setLoadingCreate(false)
               setError(errorHandeling(error))
           }

        navigate("/emp/mang_job_positions");
    }
}


  return (
    <div className='CreateNewJobPosition'>

        <div className='CreateNewJobPosition_container'>
            <span className='CreateNewJobPosition_container_header' dir="rtl">انشاء منصب جديد</span>
            <form >
            <div className='CreateNewJobPosition_container_depContent_items' dir="rtl">

                <div className='CreateNewJobPosition_container_depContent_item'>
                    <span className='CreateNewJobPosition_container_depContent_item_label' dir="rtl">اسم المنصب<span className="important_toFill">*</span></span>
                    <input type="text" className='CreateNewJobPosition_container_depContent_item_input' placeholder='اسم المنصب' dir="rtl" value={jobPName} onChange={(e)=>setJobPName(e.target.value)}/>
                </div>

                <div className='CreateNewJobPosition_container_depContent_item'>
                    <span className='CreateNewJobPosition_container_depContent_item_label' dir="rtl">الوصف الخاص بالمنصب<span className="important_toFill">*</span></span>
                    <textarea  className='CreateNewJobPosition_container_item_textarea' rows="4" cols="80" dir="rtl" value={jobPDes} onChange={(e)=>setJobPDes(e.target.value)}/>
                </div>


            </div>

            {error &&  
      
             <span className='error_in_saving' style={{ color: 'red'}} dir='rtl' >{error}</span>
            }



            {
                loadingCreate ?
                <>
                <div className='puff_loading_create_JopPosPage'>
                <TailSpin
                 visible={true}
                 height="40"
                 width="40"
                  color="rgb(246,229,56)"
                 ariaLabel="tail-spin-loading"
                 radius="1"
                 wrapperStyle={{}}
                 wrapperClass=""
                 />
                </div>
                </>
                :
                <>
                    <button type='submit' dir="rtl" className='CreatJobPos_container_depContent_submitBtn' onClick={(e)=>handleSubmit(e)}>حفظ</button>
                </>
            }



            </form>

        </div>
    </div>
  )
}

export default CreateNewJobPosition