import React, { useEffect, useState } from 'react'


import './createNewCompanyInternalNum.scss'

import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { Puff, TailSpin } from 'react-loader-spinner';

import { MdCancel } from 'react-icons/md';


import { createSelector } from 'reselect';
import { fetchAuthMe } from '../../../../store/AuthmeSlice/authmeSlice';



const selectUserPermissions = createSelector(
  state => {
    const roles = state.authme.data?.roles;
    if (!roles || roles.length === 0 || !roles[0].permessions) {
      return null; // Return null instead of a new array
    }
    return roles[0].permessions;
  },
  permessions => permessions ? permessions.map(perm => perm.name) : []
);


function CreateNewCompanyInternalNum() {

   const dispatch = useDispatch();

    const token = useSelector(state => state.user.token)

    const userPermissions = useSelector(selectUserPermissions);

    const authMeStatus = useSelector(state => state.authme.status);

    const navigate = useNavigate();

    

    
    const [depName,setDepName] = useState("")
    const [depManger,setDepManger] = useState("")
    const [depMangerId,setDepMangerId] = useState(0)

    const [depParentId,setDepParentId] = useState("")
    const [depParentName,setDepParentName] = useState("")

    const [loadingCreate,setLoadingCreate] = useState(false)
    const [loadingSearchDeps,setLoadingSearchDeps] = useState(false)
    const [loadingSearchUsers,setLoadingSearchUsers] = useState(false)

    const [departmentsArr,setDepartmentsArr] = useState([])

    const [usersArr,setUserssArr] = useState([])


    const [showSearchResultsDeps, setShowSearchResultsDeps] = useState(false);
    const [showSearchResultsUsers, setShowSearchResultsUsers] = useState(false);

    const [error,setError] = useState(null)


    useEffect(() => {
      if (authMeStatus === 'idle') {
        dispatch(fetchAuthMe());
      }
      else if (authMeStatus === 'failed') {
        setError("*خطأ في تحميل البيانات");
      }
    }, [dispatch, authMeStatus]);




  //check for permissions 

  const hasPermission = (permission) => userPermissions.includes(permission);

  const checkPermissionAndThrow = (permission) => {
    if (!hasPermission(permission)) {
      
      return false;
    }
    else{
      return true;
    }
  };




   //handel choose a department to be a department parent for our department
   const  handelSearchDepParentChange = async(e)=>{

    // console.log("the event is :",typeof (e.target.value))
    // console.log("the event is :",depParentName)


    let searchVal = e.target.value
    
    // setDepParentName(searchVal)

    if(searchVal && searchVal !== ""){
      try {
        setLoadingSearchDeps(true);
        const response = await axios.post(
          `https://emp.almatingroup.com/api/V1/departments/search/${searchVal}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        );
        console.log('Response for search:', response.data);
        setDepartmentsArr(response.data.data.data)
        setLoadingSearchDeps(false);

      } catch (error) {
        console.error('Error:', error);
        setLoadingSearchDeps(false);
      }
  
    }
    else{
      try {
        setLoadingSearchDeps(true);
        const response = await axios.get(
          'https://emp.almatingroup.com/api/V1/departments',
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        );
        console.log('Response:', response.data);
        setDepartmentsArr(response.data.data.data)
        setLoadingSearchDeps(false);

      } catch (error) {
        console.error('Error:', error);
        setLoadingSearchDeps(false);
        setError(error.message)

      }
    }

  }


//   const handelSearchChangeAndUpdateState = (e) =>{
//     console.log("the event for parent name is :",typeof (e.target.value))
//     setDepParentName("JJJJ")
//     handelSearchChange(e)
//   }

//   const handleClickOutside = (event) => {
//     if (searchInputRef.current && !searchInputRef.current.contains(event.target)) {
//         setShowSearchResults(false);
//     }
// };

// useEffect(() => {
//     document.addEventListener('mousedown', handleClickOutside);
//     return () => {
//         document.removeEventListener('mousedown', handleClickOutside);
//     };
// }, []);

const  handelDepParentChoose = (event,depId,depName) =>{
event.stopPropagation(); // Prevent click event from propagating to document listener
console.log('the dep parent id :', depId);
console.log('the dep parent name :', depName);

setDepParentName(depName);
setDepParentId(depId);
// Keep the dropdown open
setShowSearchResultsDeps(false);
}




//handel choose a manger to be a depManger for our department

const  handelSearchDepMangerChange = async(e)=>{

    // console.log("the event is :",typeof (e.target.value))
    // console.log("the event is :",depParentName)


    let searchVal = e.target.value
    
    // setDepParentName(searchVal)

    if(searchVal && searchVal !== ""){
      try {
        setLoadingSearchUsers(true);
        const response = await axios.post(
          `https://emp.almatingroup.com/api/V1/users/search/${searchVal}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        );
        console.log('Response for search:', response.data);
        setUserssArr(response.data.data.data)
        setLoadingSearchUsers(false);

      } catch (error) {
        console.error('Error:', error);
        setLoadingSearchUsers(false);
        setError(error.message);
        
      }
  
    }
    else{
      try {
        setLoadingSearchUsers(true);
        const response = await axios.get(
          'https://emp.almatingroup.com/api/V1/users',
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        );
        console.log('Response:', response.data);
        setUserssArr(response.data.data.data)
        setLoadingSearchUsers(false);

      } catch (error) {
        console.error('Error:', error);
        setLoadingSearchUsers(false);
        setError(error.message);

      }
    }

  }


  const  handelDepMangerChoose = (event,managerId,managerName) =>{
    event.stopPropagation(); // Prevent click event from propagating to document listener
    console.log('the dep parent id :', managerId);
    console.log('the dep parent name :', managerName);

    setDepManger(managerName);
    setDepMangerId(managerId);
    // Keep the dropdown open
    setShowSearchResultsUsers(false);
   }






//submit the result to the server
 
    let handleSubmit = async(e)=>{
        e.preventDefault()
        if(checkPermissionAndThrow('create_departments')){
        const data = {
            title : depName,
            manager_job_id : depMangerId , 
            parent_id: depParentId

          }
           try {
            setLoadingCreate(true)
               const response = await axios.post(`https://emp.almatingroup.com/api/V1/departments`,data, {
                   headers: {
                       Authorization: `Bearer ${token}`
                   }
               });
               // const { DepName, DepManger, DepParent } = response.data.data;
               // setDepName(DepName);
               // setDepManger(DepManger);
               // setDepParent(DepParent);
          
               // console.log("the dep after fetching is:",response.data)
               setLoadingCreate(false)
               navigate("/emp/mang_deps");

           } catch (error) {
               console.error("Error fetching department data:", error);
               setLoadingCreate(false)
               setError(error.message)
           }

          }
    }

  return (
    <div className='CreateNewInternalNum'>
      

        <div className='CreateNewInternalNum_container'>

            <span className='CreateNewInternalNum_container_header' dir="rtl">انشاء رقم داخلي</span>
            <form >
            <div className='CreateNewInternalNum_container_items' dir="rtl">




                <div className='CreateNewInternalNum_container_item'>
                    <span className='CreateNewInternalNum_container_item_label' dir="rtl">الاسم<span className='important_toFill'>*</span></span>
                    <input type="text" className='CreateNewInternalNum_container_item_input' placeholder='اسم الموظف' dir="rtl" value={depName} onChange={(e)=>setDepName(e.target.value)}/>
                </div>

  

                <div className='CreateNewInternalNum_container_item'>
                    <span className='CreateNewInternalNum_container_item_label' dir="rtl">رقم التحويلة<span className='important_toFill'>*</span></span>
                    <input type="text" className='CreateNewInternalNum_container_item_input' placeholder='رقم التحويلة' dir="rtl" value={depName} onChange={(e)=>setDepName(e.target.value)}/>
                </div>


                <div className='CreateNewInternalNum_container_item'>
                    <span className='CreateNewInternalNum_container_item_label' dir="rtl">القسم<span className='important_toFill'>*</span></span>
                    <input type="text" className='CreateNewInternalNum_container_item_input' placeholder='القسم' dir="rtl" value={depName} onChange={(e)=>setDepName(e.target.value)}/>
                </div>



            </div>
            {
                loadingCreate ?
                <>
                <div className='TailSpin_loading_create_internalNumPage'>
                <TailSpin
                 visible={true}
                 height="45"
                 width="45"
                  color="rgb(246,229,56)"
                 ariaLabel="tail-spin-loading"
                 radius="1"
                 wrapperStyle={{}}
                 wrapperClass=""
                 />
                </div>
                </>
                :
                <>
                {
                   (showSearchResultsUsers || showSearchResultsDeps) ?
                   <>
                   <span dir='rtl'>*عليك اختيار قيمة من القوائم ليسمح لك بحفظ التعديلات</span>
                   </>
                   :
                   <>
                   <div className='showButtonsAndErorrsIn_CreateInternalNum'>
                   {
                        error ? 
                        <span className='error_in_createDep' dir='rtl'>{error}</span>
                        :
                        <></>
                       }


            
                
                       <div className='show_buttons_CreateInternalNum'>

        

                      <button type='submit' dir="rtl" className='CreateInternalNum_container_submitBtn' onClick={(e)=>handleSubmit(e)}>حفظ</button>
                      
                      {/* <button type='submit' dir="rtl" className='UpdateAnOldDepatment_container_depContent_submitBtn delter_Btn_updateDepPage' onClick={(e)=>handleSubmit(e)}>حذف</button> */}

                  
                   </div>

                   </div>
                   
                   </>
                }
                
                </>
            }

      

            </form>

        </div>
    </div>
  )

}

export default CreateNewCompanyInternalNum