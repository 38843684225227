import React, { useEffect, useState } from 'react'
import './main_page.scss'
// import Footer from '../../components/Footer/Footer'
import { useDispatch, useSelector } from 'react-redux';
import Footer from '../../components/Footer/Footer';
import { TailSpin } from 'react-loader-spinner';
import { fetchAuthMe } from '../../store/AuthmeSlice/authmeSlice';
function MainPage() {
    
    const dispatch = useDispatch();


    const authMe = useSelector((state) => state.authme.data);
    
    const authMeStatus = useSelector((state) => state.authme.status);

    const [error,setError] = useState(null)

  
  useEffect(() => {
    if (!authMe) {
      dispatch(fetchAuthMe());
    }
  }, [authMe, dispatch]);



  useEffect(() => {
    if (authMeStatus === 'failed') {
      setError('خطأ في تحميل البيانات');
    }
  }, [authMeStatus]);


   // to format the date 

   const formatDate = (theDate) => {
    const date = new Date(theDate);
    // Get the day, month, and year
    const day = date.getUTCDate();
    const month = date.getUTCMonth() + 1; // Months are zero-indexed, so we add 1
    const year = date.getUTCFullYear();
    
    // Return the formatted date
    return `${month}/${day}/${year}`;
  };



  

console.log("the data comming from redux",authMe)


  return (
    <div className='main_page_full'>
    

    <div className="main_page_full_container">

        {
            error 
            ?
           <span className='error_mainPage' dir='rtl'>{error}</span>
            :
            <></>
        }

      {
       authMeStatus === 'loading'
        ?
      <>
       <div className="loading-spinner">
        <TailSpin
          visible={true}
          height="40"
          width="40"
          color="rgb(246,229,56)"
          ariaLabel="tail-spin-loading"
          radius="1"
          wrapperStyle={{}}
          wrapperClass=""
        />
        </div>
      </>
      :

      <>
      
      <div className='main_page'>
        <div className='main_page_header'>
            <span>معلومات الموظف</span>
        </div>
        <div className="main_page_content">

            <div className='main_page_right_side'>

            <div className='content_container_main_page'>
                <div className="left_main_page" >
                <span dir='rtl'>{authMe?.job_id}</span>
                    </div>
                <div className="right_main_page" dir="rtl">
                <span >الرقم الوظيفي:</span>
                </div>
            </div>

            <div className='content_container_main_page'>
                <div className="left_main_page" >
                <span dir='rtl'>{authMe?.name}</span>
                    </div>
                <div className="right_main_page" dir="rtl">
                <span >الاسم: </span>
                </div>
            </div>


            <div className='content_container_main_page'>
                <div className="left_main_page" >
                <span dir='rtl'>{authMe?.address}</span>
                    </div>
                <div className="right_main_page" dir="rtl">
                <span >العنوان: </span>
                </div>
            </div>

            
            <div className='content_container_main_page'>
                <div className="left_main_page" >
                <span >{authMe?.mobile}</span>
                    </div>
                <div className="right_main_page" dir="rtl">
                <span >رقم الموبايل: </span>
                </div>
            </div>


            {/* <div className='content_container_main_page'>
                <div className="left_main_page" >
                <span dir='rtl'></span>
                    </div>
                <div className="right_main_page" dir="rtl">
                <span >الادارة: </span>
                </div>
            </div> */}

            {/* <div className='content_container_main_page'>
                <div className="left_main_page" >
                <span dir='rtl'>الادارة المركزية</span>
                    </div>
                <div className="right_main_page" dir="rtl">
                <span >المصنع: </span>
                </div>
            </div> */}

            <div className='content_container_main_page'>
                <div className="left_main_page" >
                <span dir="rtl">{authMe?.department?.title}</span>
                    </div>
                <div className="right_main_page" dir="rtl">
                <span >القسم: </span>
                </div>
            </div>

            </div>

            <div className='main_page_left_side'>

            <div className='content_container_main_page'>
                <div className="left_main_page" >
                <span dir='rtl'>{formatDate(authMe?.hiring_date)}</span>
                    </div>
                <div className="right_main_page" dir="rtl">
                <span >تاريخ المباشرة: </span>
                </div>
            </div>

            <div className='content_container_main_page'>
                <div className="left_main_page" >
                <span dir='rtl'>{authMe?.qualification}</span>
                    </div>
                <div className="right_main_page" dir="rtl">
                <span >المؤهلات: </span>
                </div>
            </div>

            <div className='content_container_main_page'>
                <div className="left_main_page" >
                <span dir='rtl'>{authMe?.job_position?.title}</span>
                    </div>
                <div className="right_main_page" dir="rtl">
                <span >المنصب الوظيفي:</span>
                </div>
            </div>



            <div className='content_container_main_page last_main_page_element'>
                <div className="left_main_page" >
                <span  dir="rtl">{authMe?.department?.manager.name}</span>
                    </div>
                <div className="right_main_page" dir="rtl">
                <span >رئيس القسم: </span>
                </div>
            </div>

            </div>


        </div>
    </div>
      
      </>

      }







    </div>


    </div>
  )
}

export default MainPage