import React, { useEffect, useState } from 'react'
import "./empInfoJobDes.scss"
import axios from 'axios'
import { TailSpin } from 'react-loader-spinner'
import { MdCancel } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'
import { BsPersonCheck } from 'react-icons/bs'

import {addToCreateUserData, createUserCompNum} from '../../../store/CreateUserSlice/createUserSlice'
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa'




function EmpInfoJobDes() {

    const dispatch = useDispatch();

    const token = useSelector(state => state.user.token)



    const CreateUserData = useSelector(state => state.createUser.userData);

    const CreateCompNum = useSelector(state => state.createUser.compNum);


  
  const [fields,setFields] = useState({
    department_id : CreateUserData.department_id ?  CreateUserData.department_id : "",
    job_position_id: CreateUserData.job_position_id ?  CreateUserData.job_position_id : "",
    hiring_date :  CreateUserData.hiring_date ?  CreateUserData.hiring_date : "",
    job_position_title : CreateUserData.job_position_title ? CreateUserData.job_position_title : "",
    department_title : CreateUserData.department_title ? CreateUserData.department_title : ""
  })



  const [loading,setLoading] = useState(false)
  const [loadingSearchDeps,setLoadingSearchDeps] = useState(false)
  const [loadingSearchJobPostions,setLoadingSearchJobPostions] = useState(false)

  const [error,setError] = useState(null)


  const [showSearchResultsDeps, setShowSearchResultsDeps] = useState(false);
  const [showSearchResultsJobPostions, setShowSearchResultsJobPostions] = useState(false);

 



  const [departmentsArr,setDepartmentsArr] = useState([])
  const [jobPositionsArr,setJobPositionsArr] = useState([])

  const [saveChangesSuccessfully,setSaveChangesSuccessfully] = useState(false)







  const handleChange = (e) => {
    const { name, value } = e.target;
    setFields((prevFields) => ({
      ...prevFields,
      [name]: value
    }));
  };



//serarch for department to choose a department from the result
const  handelSearchDepChange = async(e)=>{

    // console.log("the event is :",typeof (e.target.value))
    // console.log("the event is :",depParentName)


    let searchVal = e.target.value
    
    // setDepParentName(searchVal)

    if(searchVal && searchVal !== ""){
      try {
        setLoadingSearchDeps(true);
        const response = await axios.post(
          `https://emp.almatingroup.com/api/V1/departments/search/${searchVal}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        );
        console.log('Response for search:', response.data.data.data);
        setDepartmentsArr(response.data.data.data)
        setLoadingSearchDeps(false);

      } catch (error) {
        console.error('Error:', error);
        setLoadingSearchDeps(false);
        setError(error.message)
      }
  
    }
    else{
      try {
        setLoadingSearchDeps(true);
        const response = await axios.get(
          'https://emp.almatingroup.com/api/V1/departments',
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        );
        console.log('Response:', response.data.data);
        setDepartmentsArr(response.data.data.data)
        setLoadingSearchDeps(false);

      } catch (error) {
        console.error('Error:', error);
        setShowSearchResultsDeps(false);
        setError(error.message)


      }
    }

  }

  const  handelDepChoose = (event,depId,depName) =>{
    event.stopPropagation(); // Prevent click event from propagating to document listener
    console.log('the dep parent id :', depId);
    console.log('the dep parent name :', depName);


    setFields((prev)=>({
        ...prev,
        department_id : depId,
        department_title : depName
    }));
    // Keep the dropdown open
    setShowSearchResultsDeps(false);
   }



//handel searching for job postions 
const  handelSearchJobPositionChange = async(e)=>{

  // console.log("the event is :",typeof (e.target.value))
  // console.log("the event is :",depParentName)


  let searchVal = e.target.value
  
  // setDepParentName(searchVal)

  if(searchVal && searchVal !== ""){
    try {
      setLoadingSearchJobPostions(true);
      const response = await axios.post(
        `https://emp.almatingroup.com/api/V1/job-positions/search/${searchVal}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      console.log('Response for search:', response.data.data.data);
      setJobPositionsArr(response.data.data.data)
      setLoadingSearchJobPostions(false);

    } catch (error) {
      console.error('Error:', error);
      setLoadingSearchJobPostions(false);
      setError(error.message)

    }

  }
  else{
    try {
      setLoadingSearchJobPostions(true);
      const response = await axios.get(
        'https://emp.almatingroup.com/api/V1/job-positions',
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      console.log('Response:', response.data.data);
      setJobPositionsArr(response.data.data.data)
      setLoadingSearchJobPostions(false);

    } catch (error) {
      console.error('Error:', error);
      setLoadingSearchJobPostions(false);
      setError(error.message)


    }
  }

}

const  handelJobPositionChoose = (event,jobPositionId,jobPositionName) =>{
  event.stopPropagation(); // Prevent click event from propagating to document listener
  console.log('the dep parent id :', jobPositionId);
  console.log('the dep parent name :', jobPositionName);

  setFields((prev)=>({
      ...prev,
      job_position_id : jobPositionId ,
      job_position_title : jobPositionName
  }));
  // Keep the dropdown open
  setShowSearchResultsJobPostions(false);
 }






 const handelMoveForword = (e)=>{
  e.preventDefault()
  if(fields.department_id === "" || fields.department_title === "" 
    || fields.job_position_id === "" || fields.job_position_title === ""
  )
  {
    setError("*يرجى التأكد من ملئ جميع الحقول الرفقة ب *")
  }
  else{
  console.log("the comp nuber is =",CreateCompNum)
 dispatch(addToCreateUserData(fields))
 dispatch(createUserCompNum(CreateCompNum + 1))
} }


const handelMoveBackword = (e)=>{
 e.preventDefault()
dispatch(createUserCompNum(CreateCompNum - 1))
}






    return (
    <div className='EmpInfoJobDes'>




    {
      saveChangesSuccessfully ?
      <div className='changePassComp_changeSuccPassMessage' dir='rtl'>
        <BsPersonCheck  className='changePassComp_changeSuccPassMessage_Icon'/>
      <span className='changePassComp_changeSuccPassMessage_textAboutSuccess' dir='rtl' >تم حفظ البيانات بنجاح</span>    
   
      </div>

      :
      <></>
    }



    <form onSubmit={handelMoveForword}>
        
   <div className="EmpsMangment_search_result_table_component_items">



                <div className='UpdateAnOldDepatment_container_depContent_item UpdateAnOldDepatment_searchbar'>
                    <span className='UpdateAnOldDepatment_container_depContent_item_label' dir="rtl">المنصب الوظيفي <span className='important_toFill'>*</span></span>
                 
                 <input type="text"  className='UpdateAnOldDepatment_container_depContent_item_input '   placeholder='المنصب الوظيفي' name='job_position_title' dir="rtl" value={fields.job_position_title} onChange={(e)=>{handleChange(e); handelSearchJobPositionChange(e)}} 
                 onFocus={(e) => {setShowSearchResultsJobPostions(true); 
                                  handelSearchJobPositionChange(e)
                                   console.log("the value",e.target.value) }
                            } 
                            
         
                />
                    
                      {showSearchResultsJobPostions && (
                        <>
                 
                        {
                          loadingSearchJobPostions ?
                          <>
                          <div className='serach_result_dep_update'>
                          <TailSpin
                          visible={true}
                          height="20"
                          width="20"
                          color="#4fa94d"
                          ariaLabel="tail-spin-loading"
                          radius="1"
                          wrapperStyle={{}}
                          wrapperClass=""
                          />
                          </div>
                          </>
                          :
                                <div className='serach_result_dep_update'>
                                    {
                                        jobPositionsArr.length === 0 ?
                                        <span>لا توجد بيانات لعرضها</span>:
                                        <>
                                         {jobPositionsArr.map((job,index) => (
                                        <span className={index === jobPositionsArr.length-1 ? 'text_inside_serachResult_dep_update last_elementShowSearch' : 'text_inside_serachResult_dep_update'} key={job.id} dir='rtl' 
                                        onClick={(event)=>{handelJobPositionChoose(event,job.id,job.title)}}>
                                            {job.title}
                                            </span>
                                    ))}
                                        </>
                                    }
               
                                </div>
                      }</> )}

                </div>





                <div className='UpdateAnOldDepatment_container_depContent_item UpdateAnOldDepatment_searchbar'>
                    <span className='UpdateAnOldDepatment_container_depContent_item_label' dir="rtl">القسم <span className='important_toFill'>*</span></span>
                 
                 <input type="text"  className='UpdateAnOldDepatment_container_depContent_item_input '   placeholder='القسم' dir="rtl" name='department_title' value={fields.department_title} onChange={(e)=>{handleChange(e); handelSearchDepChange(e)}} 
                 onFocus={(e) => {setShowSearchResultsDeps(true); 
                                  handelSearchDepChange(e)
                                   console.log("the value",e.target.value) }
                            } 
                            
         
                />
                    
                      {showSearchResultsDeps && (
                        <>
                 
                        {
                          loadingSearchDeps ?
                          <>
                          <div className='serach_result_dep_update'>
                          <TailSpin
                          visible={true}
                          height="20"
                          width="20"
                          color="#4fa94d"
                          ariaLabel="tail-spin-loading"
                          radius="1"
                          wrapperStyle={{}}
                          wrapperClass=""
                          />
                          </div>
                          </>
                          :
                                <div className='serach_result_dep_update'>
                                    {
                                        departmentsArr.length === 0 ?
                                        <span>لا توجد بيانات لعرضها</span>:
                                        <>
                                         {departmentsArr.map((dep,index) => (
                                        <span className={index === departmentsArr.length-1 ? 'text_inside_serachResult_dep_update last_elementShowSearch' : 'text_inside_serachResult_dep_update'} key={dep.id} dir='rtl' 
                                        onClick={(event)=>{handelDepChoose(event,dep.id,dep.title)}}>
                                            {dep.title}
                                            </span>
                                    ))}
                                        </>
                                    }
               
                                </div>
                      }</> )}

                </div>




       <div className="EmpsMangment_search_result_table_component_item">
      <label dir="rtl">تاريخ المباشرة <span className='important_toFill'>*</span></label>
      <input type="date" dir="rtl" value={fields.hiring_date}  name='hiring_date' onChange={handleChange} />
      </div>




   
      {/* <div className="EmpsMangment_search_result_table_component_item">
      <label dir="rtl">الادارة</label>
      <input type="text" dir="rtl" value=""  onChange={()=>{console.log("hi")}}/>
      </div> */}





    </div>

    
    {error &&  
      
      <span className='error_in_saving' style={{ color: 'red', marginRight: '3rem', marginTop: '1rem' }}  dir='rtl'>{error}</span>
      }
      


      <div className='buttons_toMoveBackAndForNext'>

{
    loading ? 
     <>
       <TailSpin
       visible={true}
       height="22"
       width="22"
       color="white"
       ariaLabel="tail-spin-loading"
       radius="1"
       wrapperStyle={{}}
       wrapperClass=""
       />
     </>
     :
     <>
       <button dir="rtl" type='submit' className='forNextButton buttons_toMoveBackAndForNext_button' onClick={(e)=>{handelMoveForword(e)}} disabled={loading}>التالي <FaAngleLeft className='buttons_toMoveBackAndForNext_button_icon' /></button>
       <button dir="rtl" type='submit' className='BackButton buttons_toMoveBackAndForNext_button' onClick={(e)=>{handelMoveBackword(e)}} disabled={loading}>السابق <FaAngleRight className='buttons_toMoveBackAndForNext_button_icon' /></button>
    
     </>
     }


 </div>
   </form>
    </div>
  )
}

export default EmpInfoJobDes