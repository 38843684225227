import React from 'react'
import Footer from '../components/Footer/Footer'
import './mainLayout.scss'
import { Outlet } from 'react-router-dom'
import Navbar from '../components/Navbar/Navbar'

function MainLayout() {
  return (
    <div className='MainLayout'>
         <Navbar/>
         
         <div className='wrapper'>
            <Outlet/>  
         </div>

        
        <Footer/>
    </div>
  )
}

export default MainLayout