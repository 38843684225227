import React, { useEffect, useState } from 'react'

import './mangmentJobPositions.scss'


import { Link, useNavigate} from "react-router-dom";
import {  useSelector } from 'react-redux';
import axios from 'axios';
import { TailSpin } from 'react-loader-spinner';

import { createSelector } from 'reselect';
import { FaPlus } from 'react-icons/fa';
import { errorHandeling } from '../../../errorHandeling';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa6';

const selectUserPermissions = createSelector(
  state => {
    const roles = state.authme.data?.roles;
    if (!roles || roles.length === 0 || !roles[0].permessions) {
      return null; // Return null instead of a new array
    }
    return roles[0].permessions;
  },
  permessions => permessions ? permessions.map(perm => perm.name) : []
);



function MangmentJobPositions() {


  const token = useSelector(state => state.user.token)

  const userPermissions = useSelector(selectUserPermissions);


  const [posArr,setPosArr] = useState([])
  const [loadingResult,setLoadingResult] = useState(false)

  const [currentPage, setCurrentPage] = useState(1);
  
  const [totalPages, setTotalPages] = useState(1);

  const [error,setError] = useState(null)

  const [searchVal , setSearchVal] = useState("");




     const navigate = useNavigate();


 



  //check for permissions 

  const hasPermission = (permission) => userPermissions.includes(permission);

  const checkPermissionAndThrow = (permission) => {
    if (!hasPermission(permission)) {
      
      return false;
    }
    else{
      return true;
    }
  };



  

  const fetchJobPositions = async (page) => {
    console.log("the serch val is: ",searchVal)
      
    if(searchVal && searchVal !== ""){
      try {
        setLoadingResult(true);
        const response = await axios.post(
          `https://emp.almatingroup.com/api/V1/job-positions/search/${searchVal}?page=${page}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        );
        console.log('Response for search:', response.data);
        setPosArr(response.data.data.data)
        setCurrentPage(response.data.data.current_page);
        setTotalPages(response.data.data.last_page);
        setLoadingResult(false);
        if(response.data.data.data.length === 0){
          setError("*لايوجد بيانات لعرضها")
        }
        else{
        setError(null)
        }
  
      } catch (error) {
        console.error('Error:', error);
        setLoadingResult(false);
        setError(errorHandeling(error))
      }
  
    }
  
  else{
  
    try {
      setLoadingResult(true);
      const response = await axios.get(
        `https://emp.almatingroup.com/api/V1/job-positions?page=${page}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      console.log('Response:', response.data.data.data);
      setPosArr(response.data.data.data)
      setCurrentPage(response.data.data.current_page);
      setTotalPages(response.data.data.last_page);
      setLoadingResult(false);
  
              
      if(response.data.data.data.length === 0){
        setError("*لايوجد بيانات لعرضها")
      }
      else{
      setError(null)
      }
  
  
    } catch (error) {
      console.error('Error:', error);
      setLoadingResult(false);
      setError(errorHandeling(error))
  
    }
  }
  
  };



  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      fetchJobPositions(page);
    }
  };
  
  
  const renderPageNumbers = () => {
    const pageNumbers = [];
    const startPage = Math.max(1, currentPage - 2);
    const endPage = Math.min(totalPages, currentPage + 2);
  
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <button
          key={i}
          onClick={() => handlePageChange(i)}
          className={currentPage === i ? 'buttonNum_pagination active' : 'buttonNum_pagination'}
        >
          {i}
        </button>
      );
    }
  
    return pageNumbers;
  };
  


  useEffect(() => {

  fetchJobPositions(1);
  
    
   }, [token]);
  

     // Debounced search value change
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      fetchJobPositions(1); // Fetch departments when user stops typing
    }, 1000); // 1000ms debounce delay

    return () => clearTimeout(delayDebounceFn); // Clear timeout if user types again
  }, [searchVal]);



     let handelUpdating = (id)=>{
      if(checkPermissionAndThrow("update_job_positions")){
      navigate(`/emp/update_job_position/${id}`);
      }
     }

     const  handelSearchChange = async(e)=>{

      console.log("the event is :",typeof (e.target.value))
  
      setSearchVal(e.target.value);
  
    
    }
  

  return (
    <div className='ManagmentJobPositions'>

        <div className='ManagmentJobPositions_container'>

            <div className='ManagmentDepartments_container_top_part'>
              { //check if the user has the right permission

                checkPermissionAndThrow("create_job_positions") &&
                <Link to='/emp/create_job_position'  dir="rtl"><FaPlus className='ManagmentDepartments_container_bottom_part_createbButtontext' /></Link>   
              }
           
                 <div className='ManagmentDepartments_container_top_part_searchBar'>
                    <input type="text" placeholder='ابحث عن منصب ما' dir="rtl"  onChange={(e)=>{handelSearchChange(e)}}/>
                 </div>
                <span className='ManagmentDepartments_container_top_part_header' dir="rtl">ادارة المناصب</span>
            </div>
           
           
            <div className='ManagmentJobPositions_container_bottom_part'>
               
                
            {
                  loadingResult ?
                  <>
                <TailSpin
                 visible={true}
                 height="40"
                 width="40"
                  color="rgb(246,229,56)"
                 ariaLabel="tail-spin-loading"
                 radius="1"
                 wrapperStyle={{}}
                 wrapperClass=""
                 />
                  </>
                  :

                  (error
                    ?
                    <span className='error_in_saving' style={{ color: 'red'}} dir='rtl' >{error}</span>
                    :
                    <>
                    <table className='ManagmentJobPositions_table'>
                    <thead>
                   <tr className='ManagmentJobPositions_header_row_table'>
                      <th>عدد الموظفين</th>
                      <th>اسم المنصب الوظيفي</th>              
                   </tr>
                   </thead>
  
                  <tbody>
                   { posArr.map(
                     obj =>(
                    <tr key={obj.id}  className='ManagmentJobPositions_container_bottom_part_rowTable' onClick={()=>handelUpdating(obj.id)}>
                    <td dir="rtl">{obj.employees_count || 0}</td>
                    <td dir="rtl">{obj.title}</td>
                 </tr>
                 )
                   )}
                     </tbody>
  
              </table>
                    
                    </>
                  )
 

            }


            </div>

            
    {
      loadingResult ? 
      <>
      </>
      :
      <>
      <div className="pagination_VacationStatistics">
           <button className='buttonNum_pagination_arrow' onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
            <FaAngleLeft  className={currentPage ===  1 ? 'arrow_disabled_Icon' : ''}  />
          </button>
            
        {renderPageNumbers()}
                        
        <button className={currentPage === totalPages ? 'buttonNum_pagination_arrow arrow_disabled' : 'buttonNum_pagination_arrow'} onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
              <FaAngleRight  className={currentPage === totalPages ? 'arrow_disabled_Icon' : ''} />
        </button>
       </div>
      
      </>
    }

        </div>

    </div>
  )
}

export default MangmentJobPositions