import React, { useState } from 'react'
import "./addNewPunshment.scss"
import axios from 'axios';
import { useSelector } from 'react-redux';
import { TailSpin } from 'react-loader-spinner';
import { useNavigate } from 'react-router-dom';
import { IoSearchSharp } from 'react-icons/io5';

function AddNewPunshment() {

    const token = useSelector(state => state.user.token)

    const navigate = useNavigate();

    const [fields,setFields] = useState({
        job_id:"",
        type : "UNKNOWN",
        reason : "",
        value : ""
    })

    const [empNameAfterSearch,setEmpNameAfterSearch] = useState("")


    const [loading,setLoading] = useState(false)

    const [loadingSearch,setLoadingSearch] = useState(false)
    
    const [error,setError] = useState(null)

    const [errorSearchId,setErrorSearchId] = useState(null)

  


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFields((prevFields) => ({
          ...prevFields,
          [name]: value
        }));
      };


    



    const handleSubmit = async (e)=>{
        e.preventDefault();

    
        console.log("we are submitting the data")

        if(fields.job_id === "" || fields.value === "" || fields.reason === "" ){
            setError("*تأكد من ملئ الحقول المطلوبة")
        }
    
       else{

        try {
            setLoading(true)
        
            const response = await axios.post(
              `https://emp.almatingroup.com/api/V1/penalties`,
              fields,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                  'Content-Type': 'application/json',
                },
              }
            );
            console.log('Response after submitting:', response.data);
            setLoading(false)
            navigate("/emp/punshments_hr");
        
          } catch (error) {
            console.error('Error:', error);
            setLoading(false)
            setError(error.message)
          }
        }
    
    }
    


  const handelSearchForEmpId = async()=>{
    console.log("the emp id is :",fields.job_id)

    let searchId = fields.job_id;

    if (searchId && searchId !==0) {
      try {
        setLoadingSearch(true)
        const response = await axios.get(`https://emp.almatingroup.com/api/V1/users/${searchId}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        setEmpNameAfterSearch(response.data.data.name);
        setLoadingSearch(false)
        
      } catch (error) {

        if (axios.isAxiosError(error)) {
          console.error("Axios error fetching data: ", error);
          setErrorSearchId("*خطأ : يرجى التحقق من الرمز الوظيفي المدخل");
        } 
        else{
          setErrorSearchId(error.message)
        }

        console.error("Error fetching data: ", error);
      
        

        setLoadingSearch(false)
      }
    }

  }



  return (
    <div className='AddNewPunshment'>


     <div className='AddNewPunshment_container'>
        <span className='AddNewPunshment_container_header' dir='rtl'>انشاء عقوبة</span>

        <div className='AddNewPunshment_container_penaltyItems'>

        <div className='AddNewPunshment_container_penaltyItem'>

                <div className='AddNewPunshment_container_penaltyItem_label_for_Search'>
                  {
                    loadingSearch ?
                    <>
                     <TailSpin
                      visible={true}
                      height="24"
                      width="24"
                      color="rgb(246,229,56)"
                      ariaLabel="tail-spin-loading"
                      radius="1"
                      wrapperStyle={{}}
                      wrapperClass=""
                      />
                    </>
                    :
                    <></>
                  }
                <span className='AddNewPunshment_container_penaltyItem_label' dir='rtl'>الاسم<span className='AddNewPunshment_container_penaltyItem_labelStar'>*</span></span>
              </div>

                <input type="text" placeholder={loadingSearch ? 'يتم الان البحث...': 'يرجى البحث للتأكد من وجود المستخدم'} className='AddNewPunshment_container_penaltyItem_input' name='name' dir='rtl' onChange={(e)=>{handleChange(e)}}
                          value={empNameAfterSearch}
                          readOnly // Make this input field read-only
                />
            </div>


            <div className='AddNewPunshment_container_penaltyItem'>
              <div className='AddNewPunshment_container_penaltyItem_label_for_Search'>
                <button className='AddNewPunshment_container_penaltyItem_button' onClick={()=>{handelSearchForEmpId()}}>
                    <IoSearchSharp className='AddNewPunshment_container_penaltyItem_search_icon_react'   />
                </button>
                <span className='AddNewPunshment_container_penaltyItem_label' dir='rtl'>الرقم الوظيفي<span className='AddNewPunshment_container_penaltyItem_labelStar'>*</span></span>
              </div>
              
                <input type="text" placeholder='123' className='AddNewPunshment_container_penaltyItem_input' name='job_id' value={fields.job_id} dir='rtl' onChange={(e)=>{handleChange(e)}}/>
           
               {
                errorSearchId ?
                <span className='error_forTheSearchId' dir='rtl'>{errorSearchId}</span>
                :
                <></>
               }
            </div>
            


            <div className='AddNewPunshment_container_penaltyItem'>
                <span className='AddNewPunshment_container_penaltyItem_label' dir='rtl'>قيمة العقوبة<span className='AddNewPunshment_container_penaltyItem_labelStar'>*</span></span>
                <input type="text" placeholder='44%' className='AddNewPunshment_container_penaltyItem_input' name='value' dir='rtl' onChange={(e)=>{handleChange(e)}}/>
            </div>

            <div className='AddNewPunshment_container_penaltyItem'>
                <span className='AddNewPunshment_container_penaltyItem_label' dir='rtl'>نمط العقوبة<span className='AddNewPunshment_container_penaltyItem_labelStar'>*</span></span>
                <select dir="rtl" className='AddNewPunshment_selection' value={fields.type} name='type' onChange={(e)=>{handleChange(e)}} >
                 <option value="UNKNOWN" dir="rtl">غير محدد</option>
                 <option value="FIXED" dir="rtl">ثابت</option>
                 <option value="PERCENTAGE" dir="rtl">نسبة مئوية</option>
                </select>
            </div>



            <div className='AddNewPunshment_container_penaltyItem'>
                <span className='AddNewPunshment_container_penaltyItem_label' dir='rtl'>ملاحظة</span>
                <textarea  rows="4" cols="50" dir='rtl' className='AddNewPunshment_container_penaltyItem_inputTextarea' name='notes' onChange={(e)=>{handleChange(e)}}></textarea>
            </div>


            <div className='AddNewPunshment_container_penaltyItem'>
                <span className='AddNewPunshment_container_penaltyItem_label' dir='rtl'>سبب العقوبة<span className='AddNewPunshment_container_penaltyItem_labelStar'>*</span></span>
                <textarea  rows="4" cols="50" dir='rtl' className='AddNewPunshment_container_penaltyItem_inputTextarea' name='reason' onChange={(e)=>{handleChange(e)}}></textarea>
            </div>

    

        </div>
        
        
        {
            error ?
            <span className="AddNewPunshment_error"  dir='rtl'>{error}</span>
            :
            <></>
        }


        <button type='submit' className='AddNewPunshment_container_submit' onClick={(e)=>{handleSubmit(e)}} dir='rtl' disabled={loading || loadingSearch}>
        {
       loading ? 
        <>
          <TailSpin
          visible={true}
          height="22"
          width="22"
          color="white"
          ariaLabel="tail-spin-loading"
          radius="1"
          wrapperStyle={{}}
          wrapperClass=""
          />
        </>
        :
        <>حفظ</>
        }
        </button>
     </div>
    </div>
  )
}

export default AddNewPunshment