import React, { useEffect, useState } from 'react'
import './exitPermissionStatistics.scss'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa'
import { TailSpin } from 'react-loader-spinner'
import PopupGfg from '../../VacationManagerShow/VacationReasonPopup'

function ExitPermissionStatistics() {



  const token = useSelector(state => state.user.token)

  const getDateFourDaysAgo = () => {
    const date = new Date();
    date.setDate(date.getDate() - 4);
    return date.toISOString().split('T')[0];
  }


  const [startingDateVacField , setStartingDateVacField] = useState(getDateFourDaysAgo());
  const [endingDateVacField , setEndingDateVacField] = useState(new Date().toISOString().split('T')[0] )
  

  const [vacationsArr,setVacationsArr] = useState([])

  const [loadingResult,setLoadingResult] = useState(false)
  const [loadingExcelFile,setLoadingExcelFile] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  
  const [existPermessionsNum,setExistPermessionsNum] = useState(0);

  useEffect(() => {
    fetchVacations(currentPage);
  }, [token, currentPage]);


  const fetchVacations = async (page) => {
    let data = {
      start_date: `${startingDateVacField} 23:59:59`,
    
      end_date : `${endingDateVacField} 23:59:59` ,

      type:"EXIT_PERMISSION",
      
      status: "ACCEPTED"
    };

    try {
      setLoadingResult(true);
      const response = await axios.post(
        `https://emp.almatingroup.com/api/V1/vacations/changed/statistics?page=${page}`,
         data , 
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      console.log('Response:', response.data.data.data);

      setVacationsArr(response.data.data.data);
      
      let dataVacs = response.data.data.data ;

      setExistPermessionsNum(dataVacs.length)
      setCurrentPage(response.data.data.current_page);
      setTotalPages(response.data.data.last_page);
      setLoadingResult(false);
    } catch (error) {
      console.error('Error:', error);
      setLoadingResult(false);
    }
  };






  let StartingDateVacFieldFunc = (e) => {
    setStartingDateVacField(e.target.value);
    console.log(e.target.value);
  }

  let EndingDateVacFieldFunc = (e) => {
    setEndingDateVacField(e.target.value);
    console.log(e.target.value);
  }







const handlePageChange = (page) => {
  if (page >= 1 && page <= totalPages) {
    setCurrentPage(page);
  }
};



const renderPageNumbers = () => {
  const pageNumbers = [];
  const startPage = Math.max(1, currentPage - 2);
  const endPage = Math.min(totalPages, currentPage + 2);

  for (let i = startPage; i <= endPage; i++) {
    pageNumbers.push(
      <button
        key={i}
        onClick={() => handlePageChange(i)}
        className={currentPage === i ? 'buttonNum_pagination active' : 'buttonNum_pagination'}
      >
        {i}
      </button>
    );
  }

  return pageNumbers;
};





const formatDate = (dateString) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};


//to show the reasons realted to the exist permission
const TruncatedText = (text, maxLength ) => {
  const truncatedText = text.length > maxLength ? `${text.split(' ')[0]}...` : text;

  return <>{truncatedText}</>
};


const handelShowResults = ()=>{
  setCurrentPage(1)
  fetchVacations(1)
}


const downloadExcelFile = async()=>{


  let data = {
    start_date: startingDateVacField,
  
    end_date : endingDateVacField ,

    status: "ACCEPTED"
  };

  try {
    setLoadingExcelFile(true);
    const response = await axios.post(
      `https://emp.almatingroup.com/api/V1/vacations/excel/export`,
       data , 
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
       
         responseType: 'blob'

      }
    );
    console.log('Response:', response.data.data);


      // Check the response type and content
      const contentType = response.headers['content-type'];
      
      console.log("the content type :",contentType)

      if (!contentType.includes('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')) {
        throw new Error('The server did not return a valid Excel file.');
      }

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'vacations.xlsx');
    document.body.appendChild(link);
    link.click();



    setLoadingExcelFile(false);
  } catch (error) {
    console.error('Error:', error);
    setLoadingExcelFile(false);
  }


}



  return (
    
    <div className='ExitPermissionStatistics'>


    <div className='ExitPermissionStatistics_Container'>

      <span className='ExitPermissionStatistics_Container_header' dir='rtl'>احصائيات اذونات الخروج</span>

      <div className='ExitPermissionStatistics_Container_statisticsShow'>
        <span className='ExitPermissionStatistics_Container_statisticsShow_label' dir='rtl'>اذونات الخروج الموافق عليها  :</span>
        <span className='ExitPermissionStatistics_Container_statisticsShow_num' dir='rtl'>{existPermessionsNum}</span>
      </div>

<div className='ExitPermissionStatistics_Note_div'>
    <span className='ExitPermissionStatistics_Note_word' dir='rtl'>ملاحظة :</span>
    <span className='ExitPermissionStatistics_Note_text' dir='rtl'>يتم عرض اذونات الخروج الخاصة بهذا اليوم افتراضيا لكن يمكنك تغير الفنرة الزمنية المتعلقة بالعرض .</span>
    
</div>

<div className='ExitPermissionStatistics_Time_filter_div'>
  <div className='ExitPermissionStatistics_Time_section'>
    <div className='ExitPermissionStatistics_Time_section_item'>
         <span dir='rtl'>الى</span>
         <input type='date' dir="rtl" value={endingDateVacField} onChange={EndingDateVacFieldFunc} />
    </div>
    <div className='ExitPermissionStatistics_Time_section_item'>
    <    span dir='rtl'>من</span>
         <input type='date' dir="rtl" value={startingDateVacField} onChange={StartingDateVacFieldFunc}  />
    </div>
  </div>
  <div className='ExitPermissionStatistics_button_section'>
  <button className='ExitPermissionStatistics_download_excel_file' onClick={()=>{downloadExcelFile()}} dir='rtl'  disabled={loadingResult || loadingExcelFile} >
  {
                    loadingExcelFile ?
                    <div className='spinner_getData_button'>
                    <TailSpin
                    visible={true}
                    height="20"
                    width="20"
                    color="white"
                    ariaLabel="tail-spin-loading"
                    radius="1"
                    wrapperStyle={{}}
                    wrapperClass=""
                      />
                    </div> 
                    :
                    <>
                    تحميل كملف اكسل
                    </>

  }


  </button>
  <button className='ExitPermissionStatistics_show_results' dir='rtl' onClick={()=>{handelShowResults()}} disabled={loadingResult} >
  {
                    loadingResult ?
                    <div className='spinner_getData_button'>
                    <TailSpin
                    visible={true}
                    height="20"
                    width="20"
                    color="white"
                    ariaLabel="tail-spin-loading"
                    radius="1"
                    wrapperStyle={{}}
                    wrapperClass=""
                      />
                    </div> 
                    :
                    <>
                    عرض النتائج
                    </>

  }

    </button>
  </div>

</div>

<div className='ExitPermissionStatistics_containerForTable'>


{
                loadingResult ?
               <div className='spinner_getData'>
               <TailSpin
               visible={true}
               height="40"
               width="40"
               color="rgb(246,229,56)"
               ariaLabel="tail-spin-loading"
               radius="1"
               wrapperStyle={{}}
               wrapperClass=""
                 />
               </div>
               :
               <>
               <span className='ExitPermissionStatistics_tableTitle' dir="rtl">اذونات الخروج </span>
            
            <table className='ExitPermissionStatistics_table'>
              <thead>
            <tr className='ExitPermissionStatistics_header_row_table'>
              <th>رقم الموبايل</th>
              <th>السبب</th>
              <th>تاريخ البداية</th>
              <th>القسم</th>
              <th>الاسم</th>
              <th>الرقم الوظيفي</th>
            </tr>
            </thead>
            
            <tbody>
              { vacationsArr?.map(
                obj =>(
                  <tr key={obj.id}>
                        <td dir="rtl">{obj.employee?.mobile}</td>
                       <td dir="rtl"><PopupGfg reason={obj.reasons || ""} firstWord = {TruncatedText(obj.reasons || "",10)}/></td>
                       <td dir="rtl">{formatDate(obj.start_date)}</td>
                       <td dir="rtl">{obj.employee?.department?.title}</td>
                       <td dir="rtl">{obj.employee?.name}</td>
                       <td dir="rtl">{obj.employee?.job_id}</td>
            </tr>
                )
              )}
            </tbody>
            
            </table>
            
            
            
                <div className="pagination_ExitPermissionStatistics">
                        <button className='buttonNum_pagination_arrow' onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
                           <FaAngleLeft  className={currentPage ===  1 ? 'arrow_disabled_Icon' : ''}  />
                        </button>
            
                        {renderPageNumbers()}
                        
                        <button className={currentPage === totalPages ? 'buttonNum_pagination_arrow arrow_disabled' : 'buttonNum_pagination_arrow'} onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
                        <FaAngleRight  className={currentPage === totalPages ? 'arrow_disabled_Icon' : ''} />
                        </button>
                      </div>
                      
               </>
               }




</div>
</div>

</div>
  )
}

export default ExitPermissionStatistics