import React, { useState } from 'react'

import './evaluationForEmployeeShowPage.scss'
import EvalSectionForEmoloyee from '../../components/EvaluationEmployeeComponents/EvalSectionForEmployee/EvalSectionForEmoloyee'
import InsuranceSectionForEmployee from '../../components/EvaluationEmployeeComponents/InsuranceSectionForEmployee/InsuranceSectionForEmployee';
import PunchmentSectionForEmplouyee from '../../components/EvaluationEmployeeComponents/PunchmenSectionForEmployee/PunchmentSectionForEmplouyee';
import LeftPersonalPapersSection from '../../components/EvaluationEmployeeComponents/LeftPersonalPapaersSection/LeftPersonalPapersSection';


function EvaluationForEmployeeShowPage() {

  const [showComp,setShowComp] = useState('1');

  return (
    <div className='EvaluationForEmployeeShowPage'>


    <div className='EvaluationForEmployeeShowPage_container'>
        <EvalSectionForEmoloyee/>

        <div className='EvaluationForEmployeeShowPage_moreInfoSections'>
            <div className='EvaluationForEmployeeShowPage_moreInfoSections_showInfo'>
            {
              showComp === '1' ?
              <InsuranceSectionForEmployee/> :
              showComp === '2' ?
              <PunchmentSectionForEmplouyee/>
              :
              <LeftPersonalPapersSection/>
            }
            </div>

           <div className='EvaluationForEmployeeShowPage_moreInfoSections_choices'>
              <span className='EvaluationForEmployeeShowPage_moreInfoSections_choices_header' dir="rtl">معلومات اخرى</span>
              <div className='EvaluationForEmployeeShowPage_moreInfoSections_choices_items'>
                <span className={showComp === '1' ? 'EvaluationForEmployeeShowPage_moreInfoSections_choices_item activeEvalShowComp' : 'EvaluationForEmployeeShowPage_moreInfoSections_choices_item'} onClick={()=>setShowComp('1')}>معلومات التأمينات</span>
                <span className={showComp === '2' ? 'EvaluationForEmployeeShowPage_moreInfoSections_choices_item activeEvalShowComp' : 'EvaluationForEmployeeShowPage_moreInfoSections_choices_item'} onClick={()=>setShowComp('2')}>معلومات العقوبة</span>
                <span className={showComp === '3' ? 'EvaluationForEmployeeShowPage_moreInfoSections_choices_item EvaluationForEmployeeShowPage_moreInfoSections_choices_item_bottom activeEvalShowComp' : 'EvaluationForEmployeeShowPage_moreInfoSections_choices_item EvaluationForEmployeeShowPage_moreInfoSections_choices_item_bottom'}
                 onClick={()=>setShowComp('3')}>الأوراق الشخصية المتبقية</span>
              </div>
           </div>


        </div>
    </div>

 

    </div>
  )
}

export default EvaluationForEmployeeShowPage