import React, { useEffect, useState } from 'react'
import './empsmangment.scss'
import { IoSearchSharp } from "react-icons/io5";
import { BsFillPencilFill } from "react-icons/bs";
import { FaRegTrashAlt } from "react-icons/fa";
import EvaluationInfos from '../../components/EvaluationInfos/EvaluationInfos';
import RestPapers from '../../components/RestPapers/RestPapers';
import ChangePassword from '../../components/ChangePassword/ChangePassword';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { TailSpin } from 'react-loader-spinner';
import EmpInfoToChangeMainComp from '../../components/EmpInfoToChange/EmpInfoToChangeMainComp/EmpInfoToChangeMainComp';

import { createSelector } from 'reselect';
import { fetchAuthMe } from '../../store/AuthmeSlice/authmeSlice';


const selectUserPermissions = createSelector(
  state => {
    const roles = state.authme.data?.roles;
    if (!roles || roles.length === 0 || !roles[0].permessions) {
      return null; // Return null instead of a new array
    }
    return roles[0].permessions;
  },
  permessions => permessions ? permessions.map(perm => perm.name) : []
);



function EmpsMangment() {

  const dispatch = useDispatch();

  const token = useSelector(state => state.user.token)

  const userPermissions = useSelector(selectUserPermissions);

  
  const authMeStatus = useSelector(state => state.authme.status);

  // console.log("the token is right now :",token)
  

  const [tableNumber,SetTableNumber] = useState(2);
  const [openChangableTable,SetopenChangableTable] = useState(false);

  const [searchId ,setSearchId] = useState(0)
  const [searchResult, setSearchResult] = useState(null);

  const [loading,setLoading] = useState(false)
  const [error,setError] = useState("")

  const [loadingDelete,setLoadingDelete] = useState(false)






  useEffect(()=>{
    console.log("the search Id is:",searchId)
    console.log("the search result is:",searchResult)

  },
  [searchId,searchResult])




  // useEffect(() => {
  //   const fetchData = async () => {
  //     if (searchId) {
  //       try {
  //         const response = await axios.get(`https://emp.almatingroup.com/api/V1/users/${searchId}`, {
  //           headers: {
  //             Authorization: `Bearer ${token}`
  //           }
  //         });
  //         setSearchResult(response.data);
  //         SetSearchBool(false);
  //       } catch (error) {
  //         console.error("Error fetching data: ", error);
  //         setSearchResult(null);
  //         SetSearchBool(true);
  //       }
  //     }
  //   };

  //   fetchData();
  // }, [searchId]);




//check for permissions 

const hasPermission = (permission) => userPermissions.includes(permission);

const checkPermissionAndThrow = (permission) => {
  if (!hasPermission(permission)) {
    setError("You do not have permission to perform this action.");
    return false;
  }
  else{
    return true;
  }
};






  const handelSearchId =  async(e)=>{
     e.preventDefault()
  
       console.log("the type of the search id :",typeof searchId)
      if (searchId && searchId !==0 && searchId.trim() !== "") {
        try {
          setLoading(true)
          let response;

          let data ;

          if (isNaN(parseInt(searchId))) {
            // Use the search endpoint if searchId is not a valid number
            response = await axios.post(`https://emp.almatingroup.com/api/V1/users/search/${searchId}`, 
              {},
              {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            });

            data = response.data.data.data[0];

            if (!data) {
              throw new Error("خطأ : يرجى التحقق من البيانات المدخلة");
            }

          } else {
            // Use the existing endpoint if searchId is a valid number
            response = await axios.get(`https://emp.almatingroup.com/api/V1/users/${searchId}`,
               {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            });

            data = response.data.data;
          }


          //  response = await axios.get(`https://emp.almatingroup.com/api/V1/users/${searchId}`, {
          //   headers: {
          //     Authorization: `Bearer ${token}`
          //   }
          // });
          setSearchResult(data);
          setLoading(false)
          
        } catch (error) {

          if (axios.isAxiosError(error)) {
            console.error("Axios error fetching data: ", error);
            setError("خطأ : يرجى التحقق من البيانات المدخلة");
          } else {
            console.error("Unexpected error: ", error);
            setError(error.message);
          }


          console.error("Error fetching data: ", error);
          setSearchResult(null);
          setLoading(false)
        }
      }
      else{
       
        SetopenChangableTable(false)
        setSearchResult(null)

      }
    

}


  let sayHi = ()=>{
    if(tableNumber===1)
      return<><EmpInfoToChangeMainComp empInfo={searchResult}/></>;
    else if(tableNumber===2)
      return<><ChangePassword empInfo={searchResult} url={`https://emp.almatingroup.com/api/V1/users/${searchResult && searchResult.job_id}/change-password`} isChangerIsUser={false} /></>
    else if(tableNumber===3)
      return<><RestPapers empInfo={searchResult} /></>
    else if(tableNumber===4)
      return<><EvaluationInfos/></>

    
  }


const handelDelete= async ()=>{

  if(checkPermissionAndThrow('delete_users')){

  console.log("the delete is working")
  
    try {
     // setLoading(true)
     setLoadingDelete(true)
      const response = await axios.delete(`https://emp.almatingroup.com/api/V1/users/${searchResult && searchResult.job_id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      SetopenChangableTable(false)
      setSearchResult("");
      // setLoading(false)
      setLoadingDelete(false)
      
    } catch (error) {

      if (axios.isAxiosError(error)) {
        console.error("Axios error fetching data: ", error);
        setError("خطأ : يرجى التحقق من البيانات المدخلة");
      } else {
        console.error("Unexpected error: ", error);
        setError("هناك خطأ ما يرجى اعادة المحاولة");
      }


      console.error("Error fetching data: ", error);
      setSearchResult(null);
      // setLoading(false)
      setLoadingDelete(false)
    }
  

}

}


  return (
    <div className='EmpsMangment_full_page'>
 

     <div className="EmpsMangment_container">
      <div className="EmpsMangment_search">
      <form onSubmit={handelSearchId}>

      <button className="EmpsMangment_search_btn" type="submit" onClick={(e)=>{handelSearchId(e)}} disabled={loading}>
        <IoSearchSharp className='EmpsMangment_search_icon_react'   />
        </button>
        <input type="text" placeholder='الرقم الوظيفي' dir="rtl" className='EmpsMangment_search_inp' onChange={(e)=>{setSearchId(e.target.value)}}/>
        
        </form>
      </div>
{
  loading ?
  <div className='spinner_searchId_empsManagment'>
    <TailSpin
    visible={true}
    height="40"
    width="40"
     color="rgb(246,229,56)"
    ariaLabel="tail-spin-loading"
    radius="1"
    wrapperStyle={{}}
    wrapperClass=""
    />
  </div>
  
  :

  searchResult ?
  <>
  
  <div className="EmpsMangment_search_result">

<div className="EmpsMangment_search_result_item">
<table>
  <thead>
  <tr className='EmpsMangment_header_row_table'>
    <th>خيارات</th>
    <th>الاسم</th>
    <th>الرقم الوظيفي</th>
  </tr>
  </thead>

 <tbody>
  <tr>
     <td>
      <div className='EmpsMangment_table_choices'>
        {
          loadingDelete ?
          <TailSpin
          visible={true}
          height="20"
          width="20"
           color="red"
          ariaLabel="tail-spin-loading"
          radius="1"
          wrapperStyle={{}}
          wrapperClass=""
          />
          :

          <button onClick={()=>{handelDelete()}}>
          <FaRegTrashAlt className='EmpsMangment_table_trash'/>
          </button>

        }
  

        <button>
        <BsFillPencilFill className='EmpsMangment_table_pen' onClick={()=>{ SetopenChangableTable(!openChangableTable)}}/>
        </button>
      </div>
     </td>
     <td dir="rtl">{searchResult && searchResult.name}</td>
     <td dir="rtl">{searchResult && searchResult.job_id}</td>
  </tr>
  </tbody>

</table>
</div>

{ openChangableTable &&
<div  className="EmpsMangment_search_result_table" >
<div className="EmpsMangment_search_result_table_top">
  <button onClick={()=>{SetTableNumber(4)}} className={ tableNumber===4 ? 'activeBtn':''}>معلومات التقييم</button>
  <button onClick={()=>{SetTableNumber(3)}} className={ tableNumber===3 ? 'activeBtn':''}>الأوراق الشخصية المتبقية</button>
  <button onClick={()=>{SetTableNumber(2)}} className={ tableNumber===2 ? 'activeBtn':''}>تغيير كلمة المرور</button>
  <button onClick={()=>{SetTableNumber(1)}} className={ tableNumber===1 ? 'activeBtn':''}>معلومات الموظف</button>

</div>

<div className="EmpsMangment_search_result_table">

{
   (sayHi)()
}

</div>


</div>

}


</div>
  
  </>
  :
  <>

        <div className="EmpsMangment_search_result_note">
        <span dir="rtl">أدخل الرقم الوظيفي الخاص بالموظف مثل 1234</span>

      </div>
      
      {error &&  
       <div className='Wraper_error_in_searchid_empsManagment'>
        <span className='error_in_searchid_empsManagment'>{error}</span>
        </div>
        }
  </>
}



     </div>
    </div>
  )
}

export default EmpsMangment