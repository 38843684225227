import React, { useEffect, useState } from 'react'
import "./empInfoJobDes.scss"
import axios from 'axios'
import { TailSpin } from 'react-loader-spinner'
import { MdCancel } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'
import { BsPersonCheck } from 'react-icons/bs'

import { createSelector } from 'reselect';
import { fetchAuthMe } from '../../../store/AuthmeSlice/authmeSlice'
import { errorHandeling } from '../../../errorHandeling'

const selectUserPermissions = createSelector(
  state => {
    const roles = state.authme.data?.roles;
    if (!roles || roles.length === 0 || !roles[0].permessions) {
      return null; // Return null instead of a new array
    }
    return roles[0].permessions;
  },
  permessions => permessions ? permessions.map(perm => perm.name) : []
);


function EmpInfoJobDes({empInfo}) {

    const dispatch = useDispatch();

    const token = useSelector(state => state.user.token)

    const userPermissions = useSelector(selectUserPermissions);

    const authMeStatus = useSelector(state => state.authme.status);

   console.log("the props we have it in the personal data are :",empInfo)
  
  const [fields,setFields] = useState({
    department_id : empInfo.department?.id || 1,
    job_position_id:empInfo.job_position?.id || 1 ,
    hiring_date : empInfo.hiring_date || ""
  })



  const [loading,setLoading] = useState(false)
  const [loadingSearchDeps,setLoadingSearchDeps] = useState(false)
  const [loadingSearchJobPostions,setLoadingSearchJobPostions] = useState(false)

  const [error,setError] = useState(null)


  const [showSearchResultsDeps, setShowSearchResultsDeps] = useState(false);
  const [showSearchResultsJobPostions, setShowSearchResultsJobPostions] = useState(false);



  const [depName,setDepName] = useState(empInfo.department?.title || "")
  const [jobPositionName,setJobPositionName] = useState(empInfo.job_position?.title || "")

 



  const [departmentsArr,setDepartmentsArr] = useState([])
  const [jobPositionsArr,setJobPositionsArr] = useState([])

  const [saveChangesSuccessfully,setSaveChangesSuccessfully] = useState(false)







  const handleChange = (e) => {
    const { name, value } = e.target;
    setFields((prevFields) => ({
      ...prevFields,
      [name]: value
    }));
  };



//serarch for department to choose a department from the result
const  handelSearchDepChange = async(e)=>{

    // console.log("the event is :",typeof (e.target.value))
    // console.log("the event is :",depParentName)


    let searchVal = e.target.value
    
    // setDepParentName(searchVal)

    if(searchVal && searchVal !== ""){
      try {
        setLoadingSearchDeps(true);
        const response = await axios.post(
          `https://emp.almatingroup.com/api/V1/departments/search/${searchVal}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        );
        console.log('Response for search:', response.data.data.data);
        setDepartmentsArr(response.data.data.data)
        setLoadingSearchDeps(false);

      } catch (error) {
        console.error('Error:', error);
        setLoadingSearchDeps(false);
        setError(errorHandeling(error))
      }
  
    }
    else{
      try {
        setLoadingSearchDeps(true);
        const response = await axios.get(
          'https://emp.almatingroup.com/api/V1/departments',
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        );
        console.log('Response:', response.data.data);
        setDepartmentsArr(response.data.data.data)
        setLoadingSearchDeps(false);

      } catch (error) {
        console.error('Error:', error);
        setShowSearchResultsDeps(false);
        setError(errorHandeling(error))


      }
    }

  }

  const  handelDepChoose = (event,depId,depName) =>{
    event.stopPropagation(); // Prevent click event from propagating to document listener
    console.log('the dep parent id :', depId);
    console.log('the dep parent name :', depName);

    setDepName(depName);
    setFields((prev)=>({
        ...prev,
        department_id : depId
    }));
    // Keep the dropdown open
    setShowSearchResultsDeps(false);
   }



//handel searching for job postions 
const  handelSearchJobPositionChange = async(e)=>{

  // console.log("the event is :",typeof (e.target.value))
  // console.log("the event is :",depParentName)


  let searchVal = e.target.value
  
  // setDepParentName(searchVal)

  if(searchVal && searchVal !== ""){
    try {
      setLoadingSearchJobPostions(true);
      const response = await axios.post(
        `https://emp.almatingroup.com/api/V1/job-positions/search/${searchVal}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      console.log('Response for search:', response.data.data.data);
      setJobPositionsArr(response.data.data.data)
      setLoadingSearchJobPostions(false);

    } catch (error) {
      console.error('Error:', error);
      setLoadingSearchJobPostions(false);
      setError(errorHandeling(error))

    }

  }
  else{
    try {
      setLoadingSearchJobPostions(true);
      const response = await axios.get(
        'https://emp.almatingroup.com/api/V1/job-positions',
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      console.log('Response:', response.data.data);
      setJobPositionsArr(response.data.data.data)
      setLoadingSearchJobPostions(false);

    } catch (error) {
      console.error('Error:', error);
      setLoadingSearchJobPostions(false);
      setError(errorHandeling(error))


    }
  }

}

const  handelJobPositionChoose = (event,jobPositionId,jobPositionName) =>{
  event.stopPropagation(); // Prevent click event from propagating to document listener
  console.log('the dep parent id :', jobPositionId);
  console.log('the dep parent name :', jobPositionName);

  setJobPositionName(jobPositionName);
  setFields((prev)=>({
      ...prev,
      job_position_id : jobPositionId
  }));
  // Keep the dropdown open
  setShowSearchResultsJobPostions(false);
 }



   //check for permissions 

const hasPermission = (permission) => userPermissions.includes(permission);

const checkPermissionAndThrow = (permission) => {
  if (!hasPermission(permission)) {
    
    return false;
  }
  else{
    return true;
  }
};






//handel the submit of the user
  const handleSubmit = async (e)=>{
    e.preventDefault();

    if(checkPermissionAndThrow("update_users")){

    console.log("the fields to save :",fields)
    //show the result of changing the departments and dont forget to optimize not fetching all the time
    // console.log("we are submitting the data")

    let data = {
        ...empInfo,
        ...fields,

    } 

    console.log("the data is after forming it:",data)
    

    try {
        setLoading(true)
    
        const response = await axios.put(
          `https://emp.almatingroup.com/api/V1/users/${empInfo.job_id}`,
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        );
        console.log('Response after submitting:', response.data);
        setLoading(false)
        setSaveChangesSuccessfully(true)
    
      } catch (error) {
        console.error('Error:', error);
        setLoading(false)
        setError(errorHandeling(error))
        setSaveChangesSuccessfully(false)
    
      }
  

  }
}


    return (
    <div className='EmpInfoJobDes'>


<div className="EmpsMangment_search_result_table_empId">
      <span>{empInfo.job_id}</span>
      <label dir="rtl">الرقم الوظيفي</label>
    </div>



    {
      saveChangesSuccessfully ?
      <div className='changePassComp_changeSuccPassMessage' dir='rtl'>
        <BsPersonCheck  className='changePassComp_changeSuccPassMessage_Icon'/>
      <span className='changePassComp_changeSuccPassMessage_textAboutSuccess' dir='rtl' >تم حفظ البيانات بنجاح</span>    
   
      </div>

      :
      <></>
    }



    <form onSubmit={handleSubmit}>
        
   <div className="EmpsMangment_search_result_table_component_items">



                <div className='UpdateAnOldDepatment_container_depContent_item UpdateAnOldDepatment_searchbar'>
                    <span className='UpdateAnOldDepatment_container_depContent_item_label' dir="rtl">المنصب الوظيفي</span>
                 
                 <input type="text"  className='UpdateAnOldDepatment_container_depContent_item_input '   placeholder='المنصب الوظيفي' dir="rtl" value={jobPositionName} onChange={(e)=>{setJobPositionName(e.target.value); handelSearchJobPositionChange(e)}} 
                 onFocus={(e) => {setShowSearchResultsJobPostions(true); 
                                  handelSearchJobPositionChange(e)
                                   console.log("the value",e.target.value) }
                            } 
                            
         
                />
                    
                      {showSearchResultsJobPostions && (
                        <>
                 
                        {
                          loadingSearchJobPostions ?
                          <>
                          <div className='serach_result_dep_update'>
                          <TailSpin
                          visible={true}
                          height="20"
                          width="20"
                          color="#4fa94d"
                          ariaLabel="tail-spin-loading"
                          radius="1"
                          wrapperStyle={{}}
                          wrapperClass=""
                          />
                          </div>
                          </>
                          :
                                <div className='serach_result_dep_update'>
                                    {
                                        jobPositionsArr.length === 0 ?
                                        <span>لا توجد بيانات لعرضها</span>:
                                        <>
                                         {jobPositionsArr.map((job,index) => (
                                        <span className={index === jobPositionsArr.length-1 ? 'text_inside_serachResult_dep_update last_elementShowSearch' : 'text_inside_serachResult_dep_update'} key={job.id} dir='rtl' 
                                        onClick={(event)=>{handelJobPositionChoose(event,job.id,job.title)}}>
                                            {job.title}
                                            </span>
                                    ))}
                                        </>
                                    }
               
                                </div>
                      }</> )}

                </div>





                <div className='UpdateAnOldDepatment_container_depContent_item UpdateAnOldDepatment_searchbar'>
                    <span className='UpdateAnOldDepatment_container_depContent_item_label' dir="rtl">القسم</span>
                 
                 <input type="text"  className='UpdateAnOldDepatment_container_depContent_item_input '   placeholder='القسم' dir="rtl" value={depName} onChange={(e)=>{setDepName(e.target.value); handelSearchDepChange(e)}} 
                 onFocus={(e) => {setShowSearchResultsDeps(true); 
                                  handelSearchDepChange(e)
                                   console.log("the value",e.target.value) }
                            } 
                            
         
                />
                    
                      {showSearchResultsDeps && (
                        <>
                 
                        {
                          loadingSearchDeps ?
                          <>
                          <div className='serach_result_dep_update'>
                          <TailSpin
                          visible={true}
                          height="20"
                          width="20"
                          color="#4fa94d"
                          ariaLabel="tail-spin-loading"
                          radius="1"
                          wrapperStyle={{}}
                          wrapperClass=""
                          />
                          </div>
                          </>
                          :
                                <div className='serach_result_dep_update'>
                                    {
                                        departmentsArr.length === 0 ?
                                        <span>لا توجد بيانات لعرضها</span>:
                                        <>
                                         {departmentsArr.map((dep,index) => (
                                        <span className={index === departmentsArr.length-1 ? 'text_inside_serachResult_dep_update last_elementShowSearch' : 'text_inside_serachResult_dep_update'} key={dep.id} dir='rtl' 
                                        onClick={(event)=>{handelDepChoose(event,dep.id,dep.title)}}>
                                            {dep.title}
                                            </span>
                                    ))}
                                        </>
                                    }
               
                                </div>
                      }</> )}

                </div>




       <div className="EmpsMangment_search_result_table_component_item">
      <label dir="rtl">تاريخ المباشرة</label>
      <input type="date" dir="rtl" value={fields.hiring_date}  name='hiring_date' onChange={handleChange} />
      </div>






      <div className="EmpsMangment_search_result_table_component_item">
      <label dir="rtl">رقم رئيس القسم</label>
      <input type="text" dir="rtl" value={empInfo.department?.manager?.job_id || ""} readOnly={true} />
      </div>

   
      {/* <div className="EmpsMangment_search_result_table_component_item">
      <label dir="rtl">الادارة</label>
      <input type="text" dir="rtl" value=""  onChange={()=>{console.log("hi")}}/>
      </div> */}





    </div>

    
    {error &&  
      
      <span className='error_in_saving' style={{ color: 'red'}} dir='rtl' >{error}</span>
      }
      
      {
        checkPermissionAndThrow("update_users") &&
        <button dir="rtl" type='submit' className='submitButton_changeUserIfno' onClick={(e)=>{handleSubmit(e)}} disabled={loading}>
        {
       loading ? 
        <>
          <TailSpin
          visible={true}
          height="22"
          width="22"
          color="white"
          ariaLabel="tail-spin-loading"
          radius="1"
          wrapperStyle={{}}
          wrapperClass=""
          />
        </>
        :
        <>حفظ</>
        }</button>
      }


   </form>
    </div>
  )
}

export default EmpInfoJobDes