import React from 'react'
import { useSelector } from 'react-redux'
import './changepass.scss'
import ChangePassword from '../../components/ChangePassword/ChangePassword';


function ChangePass() {
  const empInfo = useSelector(state => state.user.userInfo)

  return (
    <div className='changePass'>

     <div className='changePass_container'>
             <ChangePassword empInfo={empInfo} url={`https://emp.almatingroup.com/api/V1/users/${empInfo?.job_id}/change-password`} isChangerIsUser={true}/>
     </div>

    </div>
  )
}

export default ChangePass