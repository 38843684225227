
import { useNavigate, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react'

import './updateAnOldJobPosition.scss'
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { Puff, TailSpin } from 'react-loader-spinner';
import { FaRegTrashAlt } from 'react-icons/fa';

import { createSelector } from 'reselect';
import { fetchAuthMe } from '../../../store/AuthmeSlice/authmeSlice';
import { errorHandeling } from '../../../errorHandeling';

const selectUserPermissions = createSelector(
    state => {
      const roles = state.authme.data?.roles;
      if (!roles || roles.length === 0 || !roles[0].permessions) {
        return null; // Return null instead of a new array
      }
      return roles[0].permessions;
    },
    permessions => permessions ? permessions.map(perm => perm.name) : []
  );


function UpdateAnOldJobPosition() {

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const token = useSelector(state => state.user.token)

    const userPermissions = useSelector(selectUserPermissions);

    const authMeStatus = useSelector(state => state.authme.status);
    
    const { id } = useParams();


    
    const [jobPName,setJobPName] = useState("")
    const [jobPDes,setJobPDes] = useState("")
    const [loadingUpdate,setLoadingUpdate] = useState(false)

    const [error,setError] = useState(null)


    useEffect(() => {
        if (authMeStatus === 'idle') {
          dispatch(fetchAuthMe());
        }
        else if (authMeStatus === 'failed') {
          setError("*خطأ في تحميل البيانات");
        }
      }, [dispatch, authMeStatus]);



//check for permissions 

const hasPermission = (permission) => userPermissions.includes(permission);

const checkPermissionAndThrow = (permission) => {
  if (!hasPermission(permission)) {
    
    return false;
  }
  else{
    return true;
  }
};
 


    useEffect(() => {
        const fetchJobPostionData = async () => {
            try {
                setLoadingUpdate(true)
                const response = await axios.get(`https://emp.almatingroup.com/api/V1/job-positions/${id}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

                setJobPName(response.data.data.title);
                setJobPDes(response.data.data.description);

                console.log("the dep after fetching is:",response.data.data)
                setLoadingUpdate(false)

            } catch (error) {
                console.error("Error fetching department data:", error);
                setLoadingUpdate(false)
                setError(errorHandeling(error))
            }
        };

        if(authMeStatus === "succeeded"){
             fetchJobPostionData();
        }
        
    }, [id, token,authMeStatus]);




    let handleSubmit = async(e)=>{
        e.preventDefault()

    if(checkPermissionAndThrow('update_job_positions')){

       const data = {
         title : jobPName,
         description:jobPDes
       }
        try {
            setLoadingUpdate(true)
            const response = await axios.put(`https://emp.almatingroup.com/api/V1/job-positions/${id}`,data, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            // const { DepName, DepManger, DepParent } = response.data.data;
            // setDepName(DepName);
            // setDepManger(DepManger);
            // setDepParent(DepParent);
       
            // console.log("the dep after fetching is:",response.data)
            setLoadingUpdate(false)
        } catch (error) {
            console.error("Error fetching department data:", error);
            setLoadingUpdate(false)
            setError(errorHandeling(error))
        }
 
        navigate("/emp/mang_job_positions");
    }

    }



    const handleDelete = async(e)=>{
        e.preventDefault()

        if(checkPermissionAndThrow('delete_job_positions')){
        try {
            setLoadingUpdate(true)
            const response = await axios.delete(`https://emp.almatingroup.com/api/V1/job-positions/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            // const { DepName, DepManger, DepParent } = response.data.data;
            // setDepName(DepName);
            // setDepManger(DepManger);
            // setDepParent(DepParent);
       
            // console.log("the dep after fetching is:",response.data)
            setLoadingUpdate(false)
        } catch (error) {
            console.error("Error fetching department data:", error);
            setLoadingUpdate(false)
            setError(errorHandeling(error))
        }
 
        navigate("/emp/mang_job_positions");
    }

}


  return (
    <div className='UpdateJobPosition'>

        <div className='UpdateJobPosition_container'>
            <span className='UpdateJobPosition_container_header' dir="rtl">تعديل على منصب</span>
            <form >
            <div className='UpdateJobPosition_container_depContent_items' dir="rtl">

                <div className='UpdateJobPosition_container_depContent_item'>
                    <span className='UpdateJobPosition_container_depContent_item_label' dir="rtl">اسم المنصب<span className='important_toFill'>*</span></span>
                    <input type="text" className='UpdateJobPosition_container_depContent_item_input' placeholder='اسم المنصب' dir="rtl" value={jobPName} onChange={(e)=>setJobPName(e.target.value)}/>
                </div>

                <div className='UpdateJobPosition_container_depContent_item'>
                    <span className='UpdateJobPosition_container_depContent_item_label' dir="rtl">الوصف الخاص بالمنصب<span className='important_toFill'>*</span></span>
                    <textarea  className='UpdateJobPosition_container_item_textarea' rows="4" cols="80" dir="rtl" value={jobPDes} onChange={(e)=>setJobPDes(e.target.value)}/>
                </div>


            </div>

            {error &&  
      
          <span className='error_in_saving' style={{ color: 'red'}} dir='rtl' >{error}</span>
         }

           
            {
                loadingUpdate ?
                <>
                <div className='puff_loading_update_JobPosPage'>
                <TailSpin
                 visible={true}
                 height="45"
                 width="45"
                  color="rgb(246,229,56)"
                 ariaLabel="tail-spin-loading"
                 radius="1"
                 wrapperStyle={{}}
                 wrapperClass=""
                 />
                </div>
                </>
                :
                <>
                    <div className='show_buttons_forTheUpdateJobPos'>
                    <button type='submit' dir="rtl" className='UpdateJobPosition_container_depContent_submitBtn' onClick={(e)=>handleSubmit(e)}>حفظ</button>
                      
                      {/* <button type='submit' dir="rtl" className='UpdateAnOldDepatment_container_depContent_submitBtn delter_Btn_updateDepPage' onClick={(e)=>handleSubmit(e)}>حذف</button> */}

                      <FaRegTrashAlt className='UpdateJobPosPage_table_trash' onClick={(e)=>handleDelete(e)}/>
                   </div>
                </>
            }
           
           
            </form>

        </div>
    </div>
  )
}

export default UpdateAnOldJobPosition