import "./exitPermissionsGuardsPage.scss"

import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import axios from "axios"
import { FaAngleLeft, FaAngleRight } from "react-icons/fa"
import { TailSpin } from "react-loader-spinner"
import { errorHandeling } from "../../../errorHandeling"


function ExitPermissionsGuardsPage() {


  const token = useSelector(state => state.user.token)

  const [searchValue,setSearchValue] = useState("")

  const [empsArr,setEmpsArr] = useState([])

  const [loadingResult,setLoadingResult] = useState(false)

  const [loadingSearchResult , setLoadingSearchResult] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  
  const [totalPages, setTotalPages] = useState(1);

  const [error,setError] = useState(null)


  
  //get the time and the date for the exist permission 
  const getTimeAndDateFromExistPer = (existDate) =>{

// Parse the existDate into a Date object
const parsedDate = new Date(existDate);

// Extract the date part (yyyy-mm-dd)
const datePart = parsedDate.toISOString().split('T')[0]; // "2024-09-28"

// Extract the time part (hh:mm:ss)
const timePart = parsedDate.toISOString().split('T')[1].split('.')[0]; // "21:00:00"

return {
  datePart,
  timePart
}

  }



  //fetch the the records for the statistcs status
  
  useEffect(() => {

    fetchEmpRecords(currentPage);
 
   }, [token, currentPage ]);


  
  const fetchEmpRecords = async (page) => {

    console.log("the search value is :",searchValue);

    console.log("the search value is there :",!searchValue);


   if(!searchValue){
    try {
      setLoadingResult(true);
      const response = await axios.get(
        `https://emp.almatingroup.com/api/V1/vacations/waiting/get?page=${page}`, 
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      console.log('Response:', response.data.data.data);
      setEmpsArr(response.data.data.data);
      setCurrentPage(response.data.data.current_page);
      setTotalPages(response.data.data.last_page);
      setLoadingResult(false);
      setLoadingSearchResult(false);
    } catch (error) {
      console.error('Error:', error);
      setLoadingResult(false);
      setLoadingSearchResult(false);
      setError(errorHandeling(error.message))
  
    }
  }
  else{

    try {
      setLoadingResult(true);
      setLoadingSearchResult(true);
      const response = await axios.get(
        `https://emp.almatingroup.com/api/V1/vacations/waiting/get?page=${page}`, 
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      console.log('Response:', response.data.data.data);
      setEmpsArr(response.data.data.data);
      setCurrentPage(response.data.data.current_page);
      setTotalPages(response.data.data.last_page);
      setLoadingResult(false);
      setLoadingSearchResult(false);
    } catch (error) {
      console.error('Error:', error);
      setLoadingResult(false);
      setLoadingSearchResult(false);
      setError(errorHandeling(error.message))
  
    }
  }
  
  };




  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };
  

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const startPage = Math.max(1, currentPage - 2);
    const endPage = Math.min(totalPages, currentPage + 2);
  
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <button
          key={i}
          onClick={() => handlePageChange(i)}
          className={currentPage === i ? 'buttonNum_pagination active' : 'buttonNum_pagination'}
        >
          {i}
        </button>
      );
    }
  
    return pageNumbers;
  };



 //Accepting the exist_permission from the guard 
 let handelAcceptVacation = async(vacationId)=>{

 const data = {
  status : 1,

 }

 console.log("the data is :",data)
  try {
     setLoadingResult(true);
      const response = await axios.put(`https://emp.almatingroup.com/api/V1/vacations/${vacationId}/change-status`,data, {
          headers: {
              Authorization: `Bearer ${token}`
          }
      });
 
      console.log("the dep after fetching is:",response.data)
      setLoadingResult(false);
      fetchEmpRecords(currentPage);
      
  } catch (error) {
      console.error("Error fetching department data:", error);
      setLoadingResult(false);
      setError(errorHandeling(error.message))
  }





}


//Refusing the exit_permission from the guard
let handelRefuceVacation = async(vacationId)=>{
 
  const data = {
   status : 0,
  }
 
  console.log("the data is :",data)
   try {
      setLoadingResult(true);
       const response = await axios.put(`https://emp.almatingroup.com/api/V1/vacations/${vacationId}/change-status`,data, {
           headers: {
               Authorization: `Bearer ${token}`
           }
       });

       setLoadingResult(false);
       fetchEmpRecords(currentPage);
       
   } catch (error) {
       console.error("Error fetching department data:", error);
       setLoadingResult(false);
       setError(errorHandeling(error.message))
   }
 

}


  
  return (
    <div className='ExitPermissionsGuardsPage'>

      <div className='ExitPermissionsGuardsPage_container'>
       
       <span className="ExitPermissionsGuardsPage_container_header" dir="rtl">اذونات الخروج</span>



       {
         loadingResult ?
          <div className='spinner_getData'  style={{alignSelf:"center"}}>
          <TailSpin
          visible={true}
          height="40"
          width="40"
           color="rgb(246,229,56)"
          ariaLabel="tail-spin-loading"
          radius="1"
          wrapperStyle={{}}
          wrapperClass=""
          />
        </div>
          :
        <> 


       <div className="ExitPermissionsGuardsPage_container_showData">

        {
          error &&
          <span style={{color:"red",fontSize:".9rem"}} >{error}</span>
        }

        {
          empsArr.map((obj)=>(
            <div className="card" key={obj.id}>
            <div className="card_info">
             <div className="card_items">
             <div className="card_item">
                 <span className="card_item_showInfo" dir="rtl">{obj.employee.job_id}</span>
                 <span className="card_item_label" dir="rtl">الرقم الوظيفي :</span>
               </div>
   
               <div className="card_item">
                 <span className="card_item_showInfo" dir="rtl">{obj.employee.name}</span>
                 <span className="card_item_label" dir="rtl">الاسم :</span>
               </div>
             </div>
             <div className="card_items">
               <div className="card_item">
                 <span className="card_item_showInfo" dir="rtl">{getTimeAndDateFromExistPer(obj.start_date).timePart}</span>
                 <span className="card_item_label" dir="rtl">التوقيت :</span>
               </div>
   
               <div className="card_item">
                 <span className="card_item_showInfo" dir="rtl">{getTimeAndDateFromExistPer(obj.start_date).datePart}</span>
                 <span className="card_item_label" dir="rtl">التاريخ :</span>
               </div>
             </div>
            </div>
   
        <div className="card_btns">
         <button className="card_btn_exitPer card_btn_exitPer_Cancel" onClick={()=>{handelRefuceVacation(obj.id)}} dir="rtl">لم يخرج</button>

         <button className="card_btn_exitPer" onClick={()=>{handelAcceptVacation(obj.id)}} dir="rtl">خرج</button>
         </div>

           </div>   
          ))
        }
     
          
       </div>

       <div className="ExitPermissionsGuardsPage_pagination">
           <button className='buttonNum_pagination_arrow' onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
            <FaAngleLeft  className={currentPage ===  1 ? 'arrow_disabled_Icon' : ''}  />
          </button>
            
        {renderPageNumbers()}
                        
        <button className={currentPage === totalPages ? 'buttonNum_pagination_arrow arrow_disabled' : 'buttonNum_pagination_arrow'} onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
              <FaAngleRight  className={currentPage === totalPages ? 'arrow_disabled_Icon' : ''} />
        </button>
       </div>

       </>
    }

      </div>

    </div>
  )
}

export default ExitPermissionsGuardsPage