import {  useEffect, useState } from "react"
import EvaluationForHRDraftPage from "../EvaluationForHRDraftPage/EvaluationForHRDraftPage"
import  './evaluationDraftMainComp.scss'
import EvaluateDraftPageForSixMonthes from "../EvaluateDraftPageForSixMonthes/EvaluateDraftPageForSixMonthes";
import { useLocation, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import { TailSpin } from "react-loader-spinner";
import EvaluationDraftTestPeriod from "../EvaluationDraftTestPeriod/EvaluationDraftTestPeriod";
import { createSelector } from 'reselect';


const selectUserPermissions = createSelector(
  state => {
    const roles = state.authme.data?.roles;
    if (!roles || roles.length === 0 || !roles[0].permessions) {
      return null; // Return null instead of a new array
    }
    return roles[0].permessions;
  },
  permessions => permessions ? permessions.map(perm => perm.name) : []
);



function EvaluationDraftMainComp() {

  const token = useSelector(state => state.user.token)

  const userPermissions = useSelector(selectUserPermissions);

  const location = useLocation();

  const rowData = location.state?.rowData;



  const [openEvalDraft,setOpenEvalDraft] = useState(1);




  const [tableLabels,setTableLabels] = useState([]);

  const [loading,setLoading] = useState(false)

  const [error,setError] = useState(null)


  //fetch the drafts table labels 

  useEffect(()=>{
    const fetchDraftTableLabel = async() => {

      try {
        setLoading(true);
        const response = await axios.get(
          `https://emp.almatingroup.com/api/V1/evaluation-labels`,
       
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        );
        console.log('Response tabel lables:', response.data.data.data);
        setTableLabels(response.data.data.data);
        setLoading(false);
        setError(null);
      } catch (error) {
        console.error('Error:', error);
        setLoading(false);
        setError(error.message)
    
      } 
    }

    
    fetchDraftTableLabel();
  },[token])



//check for permissions 

const hasPermission = (permission) => userPermissions.includes(permission);

const checkPermissionAndThrow = (permission) => {
  if (!hasPermission(permission)) {
    return false;
  }
  else{
    return true;
  }
};



  return (
    <div className='EvaluationDraftMainComp'>

      <div className="EvaluationDraftMainComp_container">

         {
            error && (
              <span style={{color:"red",marginTop:"1rem"}} dir="rtl">{error}</span>
            )
          }

      {
         loading ?
          <div className='spinner_getData'  style={{alignSelf:"center"}}>
          <TailSpin
          visible={true}
          height="40"
          width="40"
           color="rgb(246,229,56)"
          ariaLabel="tail-spin-loading"
          radius="1"
          wrapperStyle={{}}
          wrapperClass=""
          />
        </div>
          :
          <> 

          {
           checkPermissionAndThrow("read_evaluation_settings") && 
           <>
            <div className="EvaluationDraftMainComp_container_choiceDraft">
            <div className="EvaluationDraftMainComp_container_choiceDraft_choices">
                <span className={openEvalDraft === 2 ? "EvaluationDraftMainComp_container_choiceDraft_choice EvaluationDraftMainComp_container_choiceDraft_choice_clicked" : "EvaluationDraftMainComp_container_choiceDraft_choice"} onClick={()=>{setOpenEvalDraft(2)}} dir="rtl">نموذج تقييم الموظف</span>
                <span className="EvaluationDraftMainComp_container_choiceDraft_sperator" dir="rtl">/</span>
                <span className={openEvalDraft === 1 ? "EvaluationDraftMainComp_container_choiceDraft_choice EvaluationDraftMainComp_container_choiceDraft_choice_clicked" : "EvaluationDraftMainComp_container_choiceDraft_choice"} onClick={()=>{setOpenEvalDraft(1)}}  dir="rtl">نموذج تقييم الموارد البشرية</span>
            </div>
            <span className="EvaluationDraftMainComp_container_choiceDraft_label" dir="rtl">اختر نموذج التقييم المناسب :</span>  
         </div>
           </>
          }



         {  tableLabels.length !== 0 ? 
           (checkPermissionAndThrow("read_evaluation_settings") ? //check if the user is hr and if it is show him all the eval drafts
            (openEvalDraft === 1  // let him choose between the the drafts
            ?
            <EvaluationForHRDraftPage data = {rowData} tableRowsLabels = {tableLabels} />
            :
            <>
            {
            rowData.type === "TEST" ?  //show six months draft or test draft
            <EvaluationDraftTestPeriod data = {rowData} tableRowsLabels = {tableLabels} />
            :
            <EvaluateDraftPageForSixMonthes data = {rowData} tableRowsLabels = {tableLabels} />
            
           }
           </>)  
           :
           <>
           {  // here the user is not an hr so we just show him this drafts
           rowData.type === "TEST" ?
           <EvaluationDraftTestPeriod data = {rowData} tableRowsLabels = {tableLabels} />
           :
           <EvaluateDraftPageForSixMonthes data = {rowData} tableRowsLabels = {tableLabels} />
           
          }
          </>
          )
           :
           <></>
          }


   </>
    }

      </div>
    </div>
  )
}

export default EvaluationDraftMainComp