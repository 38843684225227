import React from 'react'
import './managmentPunshmentsByHRs.scss'

import PunshmentMangmentHRsShowComp from '../../components/PunshmentsComps/PunshmentMangmentHRsShowComp/PunshmentMangmentHRsShowComp';


function ManagmentPunshmentsByHRs() {
  return (
    <div className='ManagmentPunshmentsByHRs_fullPage'>

        <div className='ManagmentPunshmentsByHRs_container'>
 


      <PunshmentMangmentHRsShowComp/>

        </div>
    </div>
  )
}

export default ManagmentPunshmentsByHRs