import React, { useEffect, useState } from 'react'
import './vacationRequestEmp.scss'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import { TailSpin } from 'react-loader-spinner'
import { useNavigate } from 'react-router-dom'




// const selectUserPermissions = createSelector(
//   state => state.authme.data?.roles[0].permessions,
//   permessions => permessions ? permessions.map(perm => perm.name) : []
// );






function VacationRequestEmp() {


  const token = useSelector(state => state.user.token)


  const authMe = useSelector(state => state.authme.data);
  
  const navigate = useNavigate();


    // Function to get the current time in "HH:MM" format
    const getCurrentTime = () => {
      const now = new Date();
      const hours = String(now.getHours()).padStart(2, '0'); // Pad to ensure two digits
      const minutes = String(now.getMinutes()).padStart(2, '0');
      return `${hours}:${minutes}`;
    };


  const [startingDateVacField , setStartingDateVacField] = useState(new Date().toISOString().split('T')[0] )

  const [endingDateVacField , setEndingDateVacField] = useState(new Date().toISOString().split('T')[0] )

  const [exitPermissionDayField , setExitPermissionDayField] = useState(new Date().toISOString().split('T')[0] )
  

  const [reason,setReason] = useState("")

  const [loading ,setLoading] = useState(false)

  const [dateError, setDateError] = useState(null);   


  const [errorResponsable,setErrorResponsable] = useState("")


  const[vacationType,setVacationType] = useState("DEFAULT");

  const [existPermissionTime , setExistPermissionTime] = useState(getCurrentTime());






//   useEffect(() => {
//     const fetchData = ()=> {
//       const today = new Date().toISOString().split('T')[0]; 
//       setStartingDateVacField(today); 
//       setEndingDateVacField(today); 
//     };

//     fetchData();
//  }, []);

 useEffect(()=>{

 
  let validateDate = ()=>{

    // Convert string dates to Date objects for comparison
    const startDate = new Date(startingDateVacField);
    const endDate = new Date(endingDateVacField);
    const exitPermissionDay = new Date(exitPermissionDayField);

    const today = new Date();
    
  // Set the time part of the dates to 00:00:00 for comparison
  startDate.setHours(0, 0, 0, 0);
  endDate.setHours(0, 0, 0, 0);
  exitPermissionDay.setHours(0, 0, 0, 0);
  today.setHours(0, 0, 0, 0);


    console.log("the today is :",today)
    console.log("the starting date is :",startDate)





    // Check if the starting date is after the ending date
    if (startDate > endDate) {
      setDateError(" *تاريخ البداية يجب ان يكون اصغر من تاريخ نهاية الاجازة");
      setErrorResponsable("date_end")
      return; // Prevent further execution
    }
    else if(startDate < today){
      setDateError("*تاريخ بداية الاجازة يجب أن يحدد ابتداءا من اليوم  وما بعده");
      setErrorResponsable("date_start")
      return; // Prevent further execution
    }
    else if(exitPermissionDay < today){
      setDateError("*تاريخ بداية الاجازة يجب أن يحدد ابتداءا من اليوم  وما بعده");
      setErrorResponsable("date_exitPermission")
      return; // Prevent further execution
    }
    else {
      setDateError(""); // Clear any previous errors
      // Proceed with form submission logic here
      setErrorResponsable("")


    }


}


  validateDate();

 },[endingDateVacField,startingDateVacField,exitPermissionDayField ])


 let StartingDateVacFieldFunc = (e) => {
    setStartingDateVacField(e.target.value);

  }

  let EndingDateVacFieldFunc = (e) => {
    setEndingDateVacField(e.target.value);

  }





  useEffect(() => {
    if (vacationType === "DEATH_VACATION" || vacationType === "MARRIAGE_VACATION") {
      const startDate = new Date(startingDateVacField);
      startDate.setDate(startDate.getDate() + 7);
      const formattedEndDate = startDate.toISOString().split('T')[0];
      setEndingDateVacField(formattedEndDate);
    }
  }, [vacationType, startingDateVacField]);



  const handleSubmit = async (e)=>{
    e.preventDefault();


    console.log("we are submitting the data")
    if(reason?.length !== 0){
    let data = {
        job_id : authMe.job_id,
        type : vacationType ,
        start_date : vacationType ==="EXIT_PERMISSION" ? `${exitPermissionDayField} ${existPermissionTime}:00` : startingDateVacField ,
        end_date : vacationType ==="EXIT_PERMISSION" ? "" : endingDateVacField ,
        reasons : reason
       
    } 

    

    console.log("the data is after forming it:",data)
 
 
 
    try {
      setLoading(true)
  
      const response = await axios.post(
        `https://emp.almatingroup.com/api/V1/vacations`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      console.log('Response after submitting:', response.data);
      setLoading(false)

      setErrorResponsable("")
      setDateError(null)
      setReason("")
      navigate("/emp");
      
  
    } catch (error) {
      console.error('Error:', error);
      setLoading(false)
      if(error.response?.data?.message === "ERR_01"){
        setDateError("*هنالك طلب اجازة سابق يتعارض مع طلب الاجازة الحالي")
      }
      else{
        setDateError(error.message && error.message)
      }

  
    }

 
  }
  else{
    setDateError("*يجب أن تحدد سبب الاجازة قبل ارسال الطلب")
    setErrorResponsable("reason")
  }


  }






  return (
    <div className='vacationRequsetEmp'>

        <form className="vacationRequestEmp_container" onSubmit={handleSubmit}>
            <span className='vacationRequestEmp_container_title' dir="rtl">طلب اجازة</span>

            <div className="vacationRequestEmp_container_item">
                <span className='vacationRequestEmp_container_item_title' dir="rtl">الرقم الوظيفي</span>
                <span  className='vacationRequestEmp_container_item_info' dir="rtl">{authMe?.job_id}</span>
            </div>
            <div className="vacationRequestEmp_container_item">
                <span className='vacationRequestEmp_container_item_title' dir="rtl">رئيس القسم</span>
                <span  className='vacationRequestEmp_container_item_info' dir="rtl">{authMe.department?.manager?.name}</span>
            </div>

            <div className="vacationRequestEmp_changeResultType_select">
             <label className='vacationRequestEmp_changeResultType_select_label' dir="rtl">نوع الاجازة :</label>
             <select dir="rtl" className='vacationRequestEmp_changaType_selection' value={vacationType} name='vacationType' onChange={(e)=>{setVacationType(e.target.value)}}>
             <option value="DEFAULT" className='vacationRequestEmp_changaType_selection' dir="rtl">اجازة عادية</option>
             <option value="EXIT_PERMISSION" className='vacationRequestEmp_changaType_selection' dir="rtl">اذن الخروج</option>
             <option value="DEATH_VACATION" className='vacationRequestEmp_changaType_selection' dir="rtl">اجازة وفاة</option>
             <option value="MARRIAGE_VACATION" className='vacationRequestEmp_changaType_selection' dir="rtl">اجازة زواج</option>

             </select>
           </div>

           {
              (vacationType !== "DEFAULT") &&
              (
                <span className='vacationRequestEmp_changeResultType_Note' dir='rtl'>
                {
                  (vacationType === "DEATH_VACATION" || vacationType === "MARRIAGE_VACATION") ?
                  (<>
                    <span className='vacationRequestEmp_changeResultType_Note_label'>*ملاحظة:</span>
                    عدد أيام الاجازة مدفوعة الاجر الخاص بهذا النوع من الاجازة هو 7 أيام 
                  </>
                  )
                  :
                  (vacationType === "EXIT_PERMISSION") ?
                  (<>
                    <span className='vacationRequestEmp_changeResultType_Note_label'>*ملاحظة:</span>
                    يجب تحديد اليوم و الوقت الخاص باذن الخروج
                  </>
                  )
                  :
                  <></>
    
                }
    
               </span>
              )
           }



          {
            (vacationType !== "EXIT_PERMISSION") ?
            (
              <div className='vacationRequestEmp_container_date'>
              <div className="vacationRequestEmp_container_date_item">
                  <span className='vacationRequestEmp_container_date_item_title' dir="rtl">تاريخ البداية</span>
                  <input type="date" dir="rtl"  className={errorResponsable === "date_start" ? 'vacationRequestEmp_container_date_item_info vacationRequestEmp_container_date_item_info_errorDate':'vacationRequestEmp_container_date_item_info'} value={startingDateVacField} onChange={(e)=>StartingDateVacFieldFunc(e)} />
              </div>
               <div className="vacationRequestEmp_container_date_item">
                  <span className='vacationRequestEmp_container_date_item_title' dir="rtl">تاريخ النهاية</span>
                  <input type="date" dir="rtl"  className={errorResponsable === "date_end" ? 'vacationRequestEmp_container_date_item_info vacationRequestEmp_container_date_item_info_errorDate':'vacationRequestEmp_container_date_item_info'} value={endingDateVacField} onChange={(e)=>EndingDateVacFieldFunc(e)} />
              </div>
              </div>
  
            )
            :
            (
              <div className='vacationRequestEmp_exitPermission_date'>
               <div className="vacationRequestEmp_exitPermission_date_item">
                  <span className='vacationRequestEmp_exitPermission_date_item_title' dir="rtl">اليوم </span>
                  <input type="date" dir="rtl"  className={errorResponsable === "date_exitPermission" ? 'vacationRequestEmp_exitPermission_date_item_info vacationRequestEmp_exitPermission_date_item_info_errorDate':'vacationRequestEmp_exitPermission_date_item_info'} value={exitPermissionDayField} onChange={(e)=>setExitPermissionDayField(e.target.value)} />
              </div>
               <div className="vacationRequestEmp_exitPermission_date_item">
                  <span className='vacationRequestEmp_exitPermission_date_item_title' dir="rtl">التوقيت</span>
                  <input type="time" dir="rtl"  className={errorResponsable === "date_end" ? 'vacationRequestEmp_exitPermission_date_item_info vacationRequestEmp_exitPermission_date_item_info_errorDate':'vacationRequestEmp_exitPermission_date_item_info'} value={existPermissionTime} onChange={(e)=>setExistPermissionTime(e.target.value)} />
              </div>
              </div>
            )
          }


            {dateError && <p className='vacationRequestEmp_errorInDateMessage' dir="rtl">{dateError}</p>} 

            <div className="vacationRequestEmp_container_item">
                <span className='vacationRequestEmp_container_item_title' dir="rtl">الاسباب</span>
                <textarea  className={errorResponsable === "reason" ? "vacationRequestEmp_container_item_textarea vacationRequestEmp_container_item_textarea_errorReason" : "vacationRequestEmp_container_item_textarea" } rows="6" cols="50" dir="rtl" onChange={(e)=>{setReason(e.target.value)}}/>
            </div>

            <button type='submit' className='vacationRequestEmp_container_btn' disabled={errorResponsable === "date_start" || errorResponsable === "date_end" } dir="rtl">
            {
           loading ? 
           <>
          <TailSpin
          visible={true}
          height="22"
          width="22"
          color="white"
          ariaLabel="tail-spin-loading"
          radius="1"
          wrapperStyle={{}}
          wrapperClass=""
          />
         </>
        :
        <>أرسل الطلب</>
        }
        </button>

        </form>
    </div>
  )
}

export default VacationRequestEmp